import React, { useState, useRef, useEffect } from "react";
import { FaPlay } from "react-icons/fa";
import videoFile from "../assets/video.mp4"; // ✅ Import Local Video
import posterImage from "../assets/Poster.jpg"; // ✅ Import Local Poster Image

const PromoVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-200 p-6">
      {/* Heading */}
      <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 text-center mb-8 leading-tight">
        <span className="text-2xl sm:text-4xl font-extrabold text-gray-800 sm:text-gray-700 bg-gradient-to-r from-yellow-400 to-yellow-600 text-transparent bg-clip-text">
          Ver Video Promocional
        </span>
      </h1>

      {/* Video Container */}
      <div className="relative w-full max-w-5xl aspect-video shadow-lg rounded-2xl overflow-hidden border border-gray-300">
        {/* Always Show Poster Until Play Clicked */}
        {!isPlaying && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10">
            <img
              src={posterImage}
              alt="Video Poster"
              className="absolute inset-0 w-full h-full object-cover"
            />
            {/* Play Button */}
            <button
              onClick={handlePlay}
              className="relative z-20 flex items-center justify-center bg-black bg-opacity-50 rounded-full p-6 hover:bg-opacity-70 transition"
            >
              <FaPlay className="text-white w-12 h-12 md:w-16 md:h-16" />
            </button>
          </div>
        )}

        {/* Video Player */}
        <video
          ref={videoRef}
          src={videoFile}
          className="w-full h-full object-cover"
          controls={isPlaying} // Show controls only when playing
          autoPlay={isPlaying}
          playsInline
        ></video>
      </div>
    </div>
  );
};

export default PromoVideo;
