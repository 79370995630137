import React, { useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { FaUser, FaEnvelope, FaPaperPlane, FaSpinner } from "react-icons/fa";
import { ArrowLeft } from "lucide-react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleBackClick = () => {
    setIsLoading(true);
    window.location.href = "/";
  };

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Remove error when the user starts typing
    if (value.trim() !== "") {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      name: formData.name.trim() === "",
      email: formData.email.trim() === "",
      message: formData.message.trim() === "",
    };

    setErrors(newErrors);

    // Stop submission if validation fails
    if (newErrors.name || newErrors.email || newErrors.message) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        "https://backend-upwork.vercel.app/api/contact-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setFormData({ name: "", email: "", message: "" });
        setShowPopup(true); // Show success popup

        // Hide the popup after 3 seconds
        setTimeout(() => setShowPopup(false), 3000);
      } else {
        alert(`❌ Error: ${data.error}`);
      }
    } catch (error) {
      console.error("❌ Error enviando el correo:", error);
      alert("❌ Error al enviar el mensaje. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-between bg-white min-h-screen">
      <Navbar />
      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
          <div className="w-12 h-12 border-4 border-[#6b0129] border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      <button
        onClick={handleBackClick}
        className="absolute top-2 left-4 bg-[#6b0129] text-white p-2 rounded-full shadow-md hover:bg-[#6b0129] transition md:hidden"
      >
        <ArrowLeft size={20} />
      </button>

      <div className="flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-lg bg-white p-8 rounded-2xl shadow-lg border border-gray-300">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-6">
            Contáctanos
          </h2>
          <p className="text-gray-600 text-center mb-6">
            ¿Tienes preguntas o comentarios? ¡Déjanos un mensaje!
          </p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <FaUser className="absolute top-3 left-3 text-gray-400 text-lg" />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`w-full pl-10 pr-4 py-3 bg-gray-100 text-gray-900 border ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  errors.name ? "focus:ring-red-500" : "focus:ring-gray-500"
                } placeholder-gray-500`}
                placeholder="Tu nombre"
              />
            </div>

            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400 text-lg" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full pl-10 pr-4 py-3 bg-gray-100 text-gray-900 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  errors.email ? "focus:ring-red-500" : "focus:ring-gray-500"
                } placeholder-gray-500`}
                placeholder="Tu correo electrónico"
              />
            </div>

            <div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className={`w-full p-3 bg-gray-100 text-gray-900 border ${
                  errors.message ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  errors.message ? "focus:ring-red-500" : "focus:ring-gray-500"
                } placeholder-gray-500`}
                placeholder="Escribe tu mensaje..."
              ></textarea>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full flex items-center justify-center py-3 rounded-lg font-semibold transition duration-300 shadow-md ${
                loading
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-gray-700 text-white hover:bg-gray-800"
              }`}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2 text-lg" />
                  Enviando...
                </>
              ) : (
                <>
                  <FaPaperPlane className="mr-2 text-lg" />
                  Enviar Mensaje
                </>
              )}
            </button>
          </form>
        </div>
      </div>

      <Footer />

      {/* Success Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 p-4 z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl text-center max-w-sm w-full">
            <h2 className="text-xl font-bold text-gray-800 mb-2">¡Gracias!</h2>
            <p className="text-gray-600">
              Hemos recibido tu correo y nos pondremos en contacto contigo
              pronto.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
