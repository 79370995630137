import React from "react";
import {
  FaTelegramPlane,
  FaInstagram,
  FaTimes,
  FaCommentDots,
} from "react-icons/fa";
import image from "../assets/logo2.png";
import { FaFlag } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handlePayment = () => {
    navigate("/payment");
  };
  const handleContact = () => {
    navigate("/contáctanos");
  };
  const handleFaq = () => {
    navigate("/faq");
  };
  const handleComplaince = () => {
    navigate("/2257");
  };
  return (
    <footer className="hidden md:block bg-[#430B27] text-white py-8">
      <div className="container mx-auto px-8">
        {/* Top Links */}
        <div className="flex flex-wrap justify-center md:justify-between items-center text-sm mb-8">
          {/* Logo Section */}
          <div className="flex items-center justify-center md:justify-start mb-4 md:mb-0">
            <img
              src={image}
              alt="BongaModels Logo"
              className="h-8 md:h-10 mr-4"
            />
          </div>

          {/* Links Section */}
          <div className="flex flex-wrap justify-center gap-4 mb-4 md:mb-0">
            <a
              className="hover:underline cursor-pointer"
              onClick={handlePayment}
            >
              Métodos de pago
            </a>
            <a className="hover:underline cursor-pointer" onClick={handleFaq}>
              Preguntas frecuentes
            </a>
            <a
              className="hover:underline cursor-pointer"
              onClick={handleContact}
            >
              Contactar soporte
            </a>
            <a
              href="https://bongacams.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline cursor-pointer"
            >
              Cámaras gratuitas
            </a>
          </div>

          {/* Language Section */}
          <div className="flex items-center">
            {/* Flag Icon */}
            <FaFlag className="text-yellow-500 h-5 w-5 mr-2" />
            {/* Emoji Flag (Optional, remove if not needed) */}
            <span className="text-yellow-500">🇪🇸</span>
          </div>
        </div>

        {/* Middle Section */}
        <div className="flex flex-col md:flex-row justify-between items-start text-sm mb-8">
          {/* Disclaimer */}
          <div className="text-center md:text-left max-w-3xl mb-4 md:mb-0">
            <p className="text-gray-300">
              Exención de responsabilidad: Todos los usuarios que emiten en esta
              web nos han proporcionado por contrato confirmación de ser mayores
              de 18 años.
            </p>
            <a
              onClick={handleComplaince}
              className="text-white hover:underline inline-block mt-2 cursor-pointer"
            >
              18 U.S.C. 2257 Mantenimiento de Registros de Declaración de
              cumplimiento de Requisitos
            </a>
          </div>

          {/* Contact Information */}
          {/* <div className="text-center md:text-right">
            <p>spain@bongamodels.com</p>
            <p>+34 900 948 701</p>
          </div> */}
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row justify-between items-center text-sm border-t border-red-700 pt-4">
          {/* Bottom Links */}
          <div className="flex flex-wrap justify-center md:justify-start gap-4">
            <a href="#" className="hover:underline">
              © Todos los Derechos Reservados. 2025
            </a>
            {/* <a href="#" className="hover:underline">
              Términos
            </a>
            <a href="#" className="hover:underline">
              Privacidad
            </a>
            <a href="#" className="hover:underline">
              Cookies
            </a>
            <a href="#" className="hover:underline">
              Estado
            </a> */}
          </div>

          {/* Social Icons */}
          {/* <div className="flex gap-4 mt-4 md:mt-0">
            <a
              href="#"
              className="text-[#fff] hover:text-white bg-[#660345] rounded-full p-2"
            >
              <FaTelegramPlane size={18} />
            </a>
            <a
              href="#"
              className="text-[#fff] hover:text-white bg-[#660345] rounded-full p-2"
            >
              <FaInstagram size={18} />
            </a>
            <a
              href="#"
              className="text-[#fff] hover:text-white bg-[#660345] rounded-full p-2"
            >
              <FaTimes size={18} />
            </a>
            <a
              href="#"
              className="relative text-[#fff] hover:text-white bg-[#660345] rounded-full p-2"
            >
              <FaCommentDots size={18} />
              <span className="absolute -top-1 -right-1 bg-pink-500 text-xs text-white font-semibold px-1.5 py-0.5 rounded-full">
                NEW
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
