import React from "react";
import TrophiesImage from "../assets/Trophy.png";

const TopModelsAndNews = () => {
  const topModels = [
    { name: "Taani", amount: "$61,360", position: 1, color: "text-yellow-500" },
    {
      name: "VikkiExtraChe...",
      amount: "$33,071",
      position: 2,
      color: "text-gray-500",
    },
    {
      name: "Milkaff",
      amount: "$28,656",
      position: 3,
      color: "text-amber-700",
    },
  ];

  const newsFeed = [
    {
      date: "27 Ene, 2025",
      title: "This Week’s Top Models",
      content:
        "Hey there, friends! Today, we’re excited to share the latest results from our TOP contests! Once again, breathtaking models whose passionate performances captivated the majority of our users.",
    },
    {
      date: "20 Ene, 2025",
      title: "New Leaders of the BongaModels Rankings",
      content:
        "It’s time again to sum up the amazing results of the TOP contests! Everyone is eager to discover which models impressed users most and claimed an astounding position in the leaderboard.",
    },
    {
      date: "17 Ene, 2025",
      title: "¡Descuentos para miembros!",
      content:
        "¡Tras una semana llena de emociones, nuestros modelos han ofrecido grandes sorpresas! Prepárate para la próxima competición.",
    },
  ];

  return (
    <div className="container mx-auto px-0 py-0 ">
      <div className="container mx-auto px-4  flex flex-col items-center">
        {/* Top Models Section */}
        <div className="bg-white rounded-lg shadow-lg p-8 relative">
          <h2 className="text-xl sm:text-3xl font-bold text-gray-800 text-center mb-6 sm:mb-8">
            ¡Las Mejores Modelos de Este Mes!
          </h2>
          <div className="sm:hidden flex flex-col items-center space-y-2 mb-4">
            {topModels.map((model) => (
              <p
                key={model.position}
                className={`text-base font-bold ${model.color}`}
              >
                {model.position}: {model.name}{" "}
                <span className="text-gray-600">({model.amount})</span>
              </p>
            ))}
          </div>
          {/* Trophy Image */}
          <div className="relative">
            <img
              src={TrophiesImage}
              alt="Trophies"
              className="w-full max-w-lg mx-auto"
            />
            {/* Model Details */}
            {topModels.map((model, index) => (
              <div
                key={index}
                className={`absolute ${
                  index === 0
                    ? "hidden sm:block lg:top-6 lg:left-20 top-0 left-9 transform -translate-x-1/2" // Hide on mobile
                    : index === 1
                    ? "hidden sm:block lg:bottom-19 lg:left-[0%] top-[100%] left-0"
                    : " hidden sm:block lg:bottom-19 lg:right-[0%] top-[100%] right-0"
                }`}
              >
                <p
                  className={`text-center font-bold ${model.color} ${
                    index === 0 ? "text-yellow-500" : "text-gray-600"
                  } text-[10px] sm:text-xs lg:text-base`}
                >
                  {model.position}: {model.name}{" "}
                  <span className="text-gray-600">({model.amount})</span>
                </p>
              </div>
            ))}
          </div>
          {/* <div className="mt-8 text-center">
            <a href="#" className="text-blue-500 font-medium hover:underline">
              Descubre cómo poder ganar dinero como ellos, una media de 30
              euros/hora
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TopModelsAndNews;
