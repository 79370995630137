import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialAvatar from "../assets/TestimnailImage.jpg";
import TestimonialAvatar2 from "../assets/TestimonialImage2.jpg";

import TestimonialAvatar3 from "../assets/TestimonialImage3.jpg";

const testimonials = [
  {
    name: "StaceyWood",
    role: "Modelo desde 2016",
    image: TestimonialAvatar3,
    text: "BongaCams es un mundo entero con una atmósfera de amistad y compañerismo. Soy afortunada en tener muchos amigos aquí :*! Y gracias a BongaCams estoy recibiendo enormes propias al día $$$.",
  },
  {
    name: "DebraPaige",
    role: "Modelo desde 2016",
    image: TestimonialAvatar2,
    text: "¡Le tengo mucho cariño a BongaCams! Esta es mi fuente principal de ingresos. La mejor parte son los pagos semanales y el práctico sistema de retiros. ¡Me siento tranquila y cómoda trabajando en BongaCams!",
  },
  {
    name: "AshleyVasquez",
    role: "Modelo desde 2016",
    image: TestimonialAvatar, // Fixed issue here
    text: "Jamás había hecho tanto dinero hasta que encontré BongaCams! Tuve la suerte de estar entre las mejores en poco tiempo ;) Gracias equipo de soporte, siempre están listos para ayudar.",
  },
];

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Auto slide
    autoplaySpeed: 4000, // 4 seconds per slide
    responsive: [
      {
        breakpoint: 768, // Tablet & Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="mt-12 sm:mt-20 px-4">
      <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 text-center mb-8 sm:mb-12">
        Lo que dicen nuestros <span className="text-yellow-500">Modelos</span>
      </h3>

      <div className="max-w-screen-lg mx-auto">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-gray-50 rounded-xl shadow-md p-6 sm:p-8 text-center mx-auto w-full"
            >
              <div className="flex justify-center">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 sm:w-24 sm:h-24 rounded-full object-cover shadow-md border-2 sm:border-4 border-gray-200"
                />
              </div>
              <h4 className="text-lg sm:text-2xl font-semibold text-gray-800 mt-4">
                ¿Por qué trabajar con BongaCams?
              </h4>
              <p className="text-xs sm:text-sm text-gray-600 mt-3 sm:mt-4">
                “{testimonial.text}”
              </p>
              <p className="text-gray-500 italic mt-4 sm:mt-6 text-xs sm:text-sm font-medium">
                - {testimonial.name}
              </p>
              <p className="text-blue-500 text-xs sm:text-sm font-semibold">
                {testimonial.role}
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialSlider;
