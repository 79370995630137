import React, { useState } from "react";
import ContactUs from "./ContactUs";

const RegistrationOptions = () => {
  const [activeTab, setActiveTab] = useState("modelo");
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <div>
      <ContactUs isOpen={isFormOpen} setIsOpen={setIsFormOpen} />

      <div className="bg-gray-200 px-6">
        <div className="container mx-auto">
          {/* Section Title */}
          <h2 className="text-center text-lg sm:text-xl md:text-3xl font-semibold text-gray-800 mb-6 uppercase tracking-wide">
            ¿CÓMO EMPEZAR A ACTUAR EN BONGACAMS?
          </h2>

          {/* Mobile View: Styled Tabs */}
          <div className="md:hidden bg-white p-2 rounded-lg shadow-md border border-gray-300 mb-6 text-center mx-auto w-full max-w-sm">
            <button
              className={`w-1/2 py-2 text-sm font-semibold text-center rounded-md transition ${
                activeTab === "modelo"
                  ? "bg-gray-200 text-[#6b0129]"
                  : "bg-white text-gray-800"
              }`}
              onClick={() => setActiveTab("modelo")}
            >
              Registro de modelo
            </button>
          </div>

          {/* Mobile View: Toggle Content */}
          <div className="block md:hidden">
            {activeTab === "modelo" ? ( // Ensure it displays correctly for "modelo"
              <div className="bg-white p-5 rounded-lg shadow-md border border-gray-300">
                <h3 className="text-base font-bold text-gray-800 mb-2">
                  Registro de modelo
                </h3>
                <ul className="text-gray-600 mb-4 space-y-1 text-sm">
                  <li>
                    • ¡Inscribirse es muy fácil, rápido y totalmente gratis!
                  </li>
                  <li>
                    • Si eres mujer, hombre, transexual o pareja de más de 18
                    años...
                  </li>
                  <li>
                    • Tienes un ordenador, una cámara web o un smartphone con
                    buena conexión...
                  </li>
                </ul>
                <button
                  onClick={() => setIsFormOpen(true)}
                  className="w-full bg-blue-500 text-white font-semibold py-2 rounded-full hover:bg-blue-600 transition"
                >
                  Registro de modelo
                </button>
              </div>
            ) : null}{" "}
            {/* Ensures the div is only rendered when the tab is 'modelo' */}
          </div>

          {/* Desktop View */}
          <div className="hidden md:flex items-start justify-between gap-12">
            {/* Registro de estudios (Placeholder for Future) */}

            {/* Vertical Divider */}
            <div className="border-r border-gray-300 h-auto"></div>

            {/* ✅ Registro de modelo */}
            <div className="flex-1 p-6 bg-white rounded-xl shadow-lg border border-gray-200 transition-all duration-300 hover:shadow-xl">
              <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center gap-2">
                <span className="text-blue-600">🚀</span> Registro de Modelo
              </h3>

              <ul className="text-gray-700 text-lg leading-relaxed mb-6 space-y-2">
                <li className="flex items-start gap-2">
                  <span className="text-green-500">✔</span> ¡Inscribirse es muy
                  fácil, rápido y totalmente gratis!
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-green-500">✔</span> Si eres mujer,
                  hombre, transexual o pareja de más de 18 años...
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-green-500">✔</span> Tienes un ordenador,
                  una cámara web o un smartphone con buena conexión...
                </li>
              </ul>

              {/* Call-to-Action Button */}
              <button
                onClick={() => setIsFormOpen(true)}
                className="w-full py-3 text-lg font-semibold rounded-lg text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300 transform hover:scale-105 shadow-md"
              >
                🚀 Registro de Modelo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOptions;
