import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const PaymentMethods = () => {
  return (
    <div>
      <Navbar />
      <div className="max-w-5xl mx-auto bg-white p-8 rounded-lg shadow-lg border border-gray-300">
        {/* Título */}
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Pagos en BongaModels
        </h2>

        {/* Métodos de Pago */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Métodos de Pago
        </h3>
        <p className="text-gray-700 mb-4">
          Existen varios métodos de pago disponibles en BongaModels. Puedes
          elegir el que más te convenga:
        </p>
        <ul className="list-disc list-inside text-gray-800 space-y-3 mb-6">
          <li>
            <strong>Paxum</strong> (sin comisión)
          </li>
          <li>
            <strong>WebMoney</strong> (3% – comisión)
          </li>
          <li>
            <strong>Transferencia bancaria / Revolut*</strong> ($6 de comisión
            para individuos; 1% para entidades legales, pero no menos de $45)
          </li>
          <li>
            <strong>Criptomonedas:</strong> Bitcoin, Ethereum, Tether USDT
            (ERC-20), Tether USDT (TRC-20), Tether USDT (SOL), USD Coin
            (ERC-20), USD Coin (SOL) (1.5% – comisión**)
          </li>
        </ul>

        <p className="text-gray-600 text-sm italic mb-6">
          * La transferencia bancaria directa está disponible para modelos y
          estudios de todos los países de la UE, Reino Unido, Andorra, Islandia,
          Noruega, Suiza, Liechtenstein, Mónaco, San Marino, Vaticano, Isla de
          Mayotte, Saint Pierre y Miquelon, Guernsey, Jersey y la Isla de Man.
          Los fondos llegarán a tu cuenta en un plazo de tres días hábiles.
        </p>

        {/* Comisión de Criptomonedas */}
        <h4 className="text-lg font-semibold text-gray-800 mb-3">
          Comisión por retiros en criptomonedas:
        </h4>
        <ul className="list-disc list-inside text-gray-800 space-y-3 mb-6">
          <li>Bitcoin – 1.5% + 0.0004 BTC</li>
          <li>Ethereum – 1.5% + 0.003 ETH</li>
          <li>Tether USDT (ERC-20) – 1.5% + 0.003 ETH</li>
          <li>Tether USDT (TRC-20) – 1.5% + 2 TRX</li>
          <li>Tether USDT (SOL) – 1.5% + 0.0001 SOL</li>
          <li>USD Coin (ERC-20) – 1.5% + 0.003 ETH</li>
          <li>USD Coin (SOL) – 1.5% + 0.0001 SOL</li>
        </ul>

        <p className="text-gray-600 text-sm italic mb-8">
          Cuando elijas Tether USDT (ERC-20), Tether USDT (TRC-20), Tether USDT
          (SOL), USD Coin (ERC-20) o USD Coin (SOL) como método de pago,
          asegúrate de ingresar la billetera correcta – ERC-20, TRC-20 o SOL.
          Introducir una dirección de billetera incorrecta resultará en la
          pérdida de fondos sin reembolso.
        </p>

        {/* Métodos de Pago Adicionales para Modelos de EE.UU. */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Métodos de Pago Adicionales para Modelos de EE.UU.
        </h3>
        <p className="text-gray-700 mb-4">
          Existen dos opciones de pago adicionales disponibles para modelos con
          sede en EE.UU.:
        </p>
        <ul className="list-disc list-inside text-gray-800 space-y-3 mb-6">
          <li>
            <strong>Cheque</strong> (sin comisión)
          </li>
          <li>
            <strong>Depósito Directo</strong> (sin comisión)
          </li>
        </ul>

        {/* Monto Mínimo de Pago */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Monto Mínimo de Pago
        </h3>
        <p className="text-gray-700 mb-4">
          Puedes retirar fondos si tienes un saldo mínimo requerido:
        </p>
        <ul className="list-disc list-inside text-gray-800 space-y-3 mb-6">
          <li>
            <strong>$50</strong> – para modelos
          </li>
          <li>
            <strong>$100</strong> – para estudios
          </li>
        </ul>

        {/* Frecuencia de Pagos */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Frecuencia de Pagos
        </h3>
        <p className="text-gray-700 mb-4">
          Una vez alcanzado el monto mínimo, el pago se realiza semanalmente
          según las ganancias de una semana calendario (de lunes a domingo).
        </p>
        <p className="text-gray-700 mb-4">
          Todos los pagos se realizan los viernes de la semana siguiente al
          período de pago.
        </p>
        <p className="text-gray-700 mb-6">
          Si tus ganancias son menores al monto mínimo, el pago se pospondrá
          hasta el viernes siguiente hasta alcanzar el mínimo requerido.
        </p>

        {/* Pagos Anticipados */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Pagos Anticipados
        </h3>
        <p className="text-gray-700 mb-4">
          Puedes recibir tus ganancias antes si cumples con los requisitos
          mínimos. Los pagos anticipados se realizan los martes de la semana
          siguiente.
        </p>

        <h4 className="text-lg font-semibold text-gray-800 mb-3">
          Condiciones para modelos:
        </h4>
        <p className="text-gray-700 mb-4">
          Para recibir tu pago anticipado, debes estar en línea al menos **3
          horas** los días **viernes, sábado y domingo**.
        </p>

        <h4 className="text-lg font-semibold text-gray-800 mb-3">
          Condiciones para estudios:
        </h4>
        <p className="text-gray-700 mb-4">
          Más de la mitad de los modelos activos del estudio deben estar en
          línea al menos **3 horas** los días **viernes, sábado y domingo**.
        </p>

        {/* Nota Final */}
        <p className="text-gray-600 text-sm italic">
          Asegúrate de ingresar la billetera correcta para evitar la pérdida de
          fondos sin posibilidad de reembolso.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentMethods;
