import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../assets/Image1.jpg";
import Image2 from "../assets/BgImage2.jpg";
import Image3 from "../assets/Image3Bg.jpg";
import Image4 from "../assets/Imagebg4.jpg";
import Image5 from "../assets/Image0Onit.png";
import MobileImage1 from "../assets/MobileImage1.jpg";
import MobileImage2 from "../assets/ss.jpg";
import MobileImage3 from "../assets/MobileImage3.jpg";
import MobileImage4 from "../assets/MobileImage4.jpg";
import MobileImage5 from "../assets/Image0Onit.png";
import { AiOutlineArrowRight } from "react-icons/ai";

import Image6 from "../assets/Text-2.png";
import Image7 from "../assets/Text-3.png";
import WebCamImage from "../assets/SignUpImage.png";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Navbar from "./Navbar";
import PromoVideo from "./PromoVideo";
import TestimonialAvatar from "../assets/TestimnailImage.jpg";
import TopModelsAndNews from "./TopModelsAndNews";
import AwardsCarousel from "./AwardsCarousel";
import RegistrationOptions from "./RegistrationOptions";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import PopUpForm from "./PopUpForm";
import TestimonialSlider from "./TestimonialSlider";

// Custom Left Arrow
const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className=" sm:block absolute top-1/2 left-5 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 sm:p-3 rounded-full hover:bg-opacity-75 z-10 hidden"
  >
    <ChevronLeft className="w-2 h-2 sm:w-6 sm:h-6" />
  </button>
);

// Custom Right Arrow
const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="hidden sm:block absolute top-1/2 right-3 sm:right-5 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 sm:p-3 rounded-full hover:bg-opacity-75 z-10"
  >
    <ChevronRight className="w-2 h-2 sm:w-6 sm:h-6" />
  </button>
);

const HeroPage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: false,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    adaptiveHeight: false,
    cssEase: "ease-in-out",
    swipeToSlide: true,
    touchMove: true,
    pauseOnHover: false,
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Image arrays for desktop & mobile
  const desktopImages = [Image1, Image2, Image3, Image4];
  const mobileImages = [MobileImage1, MobileImage2, MobileImage3, MobileImage4];

  const images = isMobile ? mobileImages : desktopImages;

  return (
    <div>
      <Navbar />
      <ContactUs isOpen={isFormOpen} setIsOpen={setIsFormOpen} />
      {/* Slider Section with full-width background */}
      <div className="relative w-full h-[30vh] md:h-[38vh] lg:h-[80vh] overflow-hidden">
        {" "}
        <Slider {...settings} className="w-full h-full">
          {images.map((src, index) => (
            <div key={index} className="w-full h-full relative">
              <img
                src={src}
                alt={`Slider image ${index + 1}`}
                className="w-full h-full max-h-[30vh] md:max-h-[80vh] object-cover"
              />
              {index === 0 && (
                <div className="absolute lg:top-[200px] top-[100px] left-1 sm:left-5 md:left-40 transform -translate-y-1/3 flex flex-col items-center">
                  <img
                    src={Image5}
                    alt="Overlay"
                    className="w-80 sm:w-60 md:w-[300px] lg:w-[600px] h-auto object-contain"
                  />

                  {/* Apply Now Button */}
                  <div className="mt-1 flex items-center">
                    <button
                      onClick={() => setIsFormOpen(true)}
                      className="bg-[#03D0D5] text-white px-1 py-[6px] sm:px-4 sm:py-2 rounded-full font-semibold text-[12px] sm:text-2xl flex items-center gap-[2px] sm:gap-2 hover:bg-[#02B8BD] transition"
                    >
                      Apply Now
                      <AiOutlineArrowRight className="text-white text-xs sm:text-lg" />
                    </button>
                  </div>
                </div>
              )}

              {index === 1 && (
                <div className="absolute top-[75px] md:top-[120px] lg:top-[150px] left-3 md:left-32 lg:left-80 transform -translate-y-1/3">
                  <img
                    src={Image6}
                    alt="Overlay"
                    className="w-48 sm:w-48 md:w-[200px] lg:w-[400px] h-auto object-contain"
                  />
                  <div className="mt-1 flex items-center">
                    <button
                      onClick={() => setIsFormOpen(true)}
                      className="bg-[#C1356A] text-white px-1 py-[6px] sm:px-4 sm:py-2 rounded-full font-semibold text-[12px] sm:text-2xl flex items-center gap-[2px] sm:gap-2 hover:bg-[#A02B58] transition"
                    >
                      Apply Now
                      <AiOutlineArrowRight className="text-white text-xs sm:text-lg" />
                    </button>
                  </div>
                </div>
              )}
              {index === 2 && (
                <div className="absolute top-[87px] lg:top-[170px] left-3 md:left-32 transform -translate-y-1/3">
                  <img
                    src={Image7}
                    alt="Overlay"
                    className="w-52 sm:w-52 md:w-[200px] lg:w-[400px] h-auto object-contain"
                  />
                  <div
                    onClick={() => setIsFormOpen(true)}
                    className="mt-1 ml-20 lg:ml-6 flex items-center"
                  >
                    <button className="bg-[#0688F6] text-white px-1 py-[6px] sm:px-4 sm:py-2 rounded-full font-semibold text-[12px] sm:text-2xl flex items-center gap-[2px] sm:gap-2 hover:bg-[#02B8BD] transition">
                      Apply Now
                      <AiOutlineArrowRight className="text-white text-xs sm:text-lg" />
                    </button>
                  </div>
                </div>
              )}
              {index === 3 && (
                <>
                  <div className="absolute top-[170px] left-3 md:left-32 transform -translate-y-1/3 md:hidden lg:hidden">
                    <div
                      onClick={() => setIsFormOpen(true)}
                      className="mt-4 flex items-center"
                    >
                      <button className="bg-[#ad9025] text-white px-1 py-[6px] sm:px-4 sm:py-2 rounded-full font-semibold text-[12px] sm:text-2xl flex items-center gap-[2px] sm:gap-2 hover:bg-[#c7b239] transition">
                        Apply Now
                        <AiOutlineArrowRight className="text-white text-xs sm:text-lg" />
                      </button>
                    </div>
                  </div>

                  <div className="hidden md:block lg:block absolute bottom-32 left-1/4 transform -translate-x-1/2">
                    <div
                      onClick={() => setIsFormOpen(true)}
                      className="mt-4 flex "
                    >
                      <button className="bg-[#ad9025] text-white px-2 py-2 md:px-6 md:py-3 rounded-full font-semibold text-md md:text-2xl flex items-center gap-2 hover:bg-[#c7b239] transition">
                        Apply Now
                        <AiOutlineArrowRight className="text-white text-sm md:text-lg" />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </Slider>
      </div>
      {/* Main Content Section */}
      <div className="bg-gradient-to-b from-white via-gray-100 to-gray-200 py-8 px-4 sm:py-12 sm:px-6">
        <div className="container mx-auto">
          {/* Header Section */}
          <div className="text-center">
            <div className="flex justify-center mb-4 sm:mb-6">
              <img
                src={WebCamImage}
                alt="Webcam"
                className="w-24 h-24 sm:w-32 sm:h-32 object-contain rounded-full shadow-lg border-2 sm:border-4 border-yellow-500"
              />
            </div>
            <h2 className="text-lg sm:text-2xl md:text-3xl lg:text-5xl font-extrabold text-gray-800 leading-tight mb-3 sm:mb-4">
              ¡Inscríbete en <span className="text-yellow-500">BongaCams</span>{" "}
              y gana dinero!
            </h2>

            <button
              onClick={() => setIsFormOpen(true)}
              className="bg-yellow-500 text-white px-6 sm:px-10 py-3 sm:py-4 rounded-full text-sm sm:text-lg font-bold shadow-md hover:shadow-lg hover:bg-yellow-600 transition-all"
            >
              Regístrate ahora
            </button>
          </div>

          {/* Divider */}
          <div className="mt-8 sm:mt-12 border-t border-gray-300"></div>

          {/* Offers Section */}
          <div className="mt-10 sm:mt-16">
            <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 text-center mb-2  sm:mb-12">
              ¡Inscríbete en BongaCams
              <span className="text-yellow-500"> y gana dinero!</span>
            </h3>
            <p className="text-lg sm:text-xl font-medium text-gray-600 text-center mb-8">
              Ofertas Especiales
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Offer Cards */}
              {[
                {
                  title: "Más de un 50% de las ganancias",
                  description:
                    "Conviértete en modelo de BongaCams y obtén más del 50% de comisión.",
                  link: "#",
                },
                {
                  title: "Hasta $100 por cada modelo referido/a",
                  description:
                    "Recomienda a nuevos modelos y consigue hasta $100 por cada modelo o estudio.",
                  link: "#",
                },
                {
                  title: "Hasta un 25% de miembros referidos",
                  description:
                    "Invita a nuevos clientes y consigue hasta un 25% de todas sus compras.",
                  link: "#",
                },
                {
                  title: "Métodos de pago seguros",
                  description:
                    "Recibe tus ganancias a través de Paxum, Webmoney o Criptomonedas.",
                  link: "#",
                },
              ].map((offer, index) => (
                <div
                  key={index}
                  className="p-4 sm:p-6 bg-white rounded-xl shadow-md hover:shadow-xl transition-all hover:scale-105 group"
                >
                  <h4 className="text-sm sm:text-lg font-bold text-gray-700 group-hover:text-yellow-500">
                    {offer.title}
                  </h4>
                  <p className="text-xs sm:text-sm text-gray-600 mt-3 sm:mt-4">
                    {offer.description}
                  </p>
                  <button
                    onClick={() => setIsFormOpen(true)}
                    className="text-yellow-500 mt-3 sm:mt-4 inline-block text-xs sm:text-sm font-semibold hover:underline focus:outline-none"
                  >
                    Empieza a ganar dinero →
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Testimonial Section */}
          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>

      <div className="bg-gray-200">
        <TopModelsAndNews />
      </div>
      <div>
        {/* Promo Video Section */}
        <PromoVideo />
      </div>
      <div>
        <AwardsCarousel />
      </div>
      <div>
        <RegistrationOptions />
      </div>
      <div>
        <Footer />
      </div>
      <div></div>
    </div>
  );
};

export default HeroPage;
