import { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ViolationsTable from "./Voilation";

const TermsAndConditions = () => {
  const [activeTab, setActiveTab] = useState("terms");

  const tabs = [
    { id: "privacy", label: "Política de privacidad" },
    { id: "terms", label: "Pravidla a podmínky používání" },
    { id: "cookies", label: "Cookies" },
  ];

  const clausesLeft = [
    "Agreement",
    "Definitions",
    "1st clause – purpose",
    "2nd clause – duration",
    "3rd clause – fees",
    "4th clause – declarations",
    "5th clause - personal data",
    "6th clause – rights to the image and author right",
    "7th clause – duties",
  ];

  const clausesRight = [
    "8th clause - basic rules for the provision of services",
    "9th clause – assignment",
    "10th clause – amendments",
    "11th clause – termination",
    "12th clause – limitation of the liability",
    "13th clause – severability",
    "14th clause – applicable law and jurisdiction",
    "15th clause – notices",
  ];

  return (
    <div>
      <Navbar />

      <div className="max-w-5xl mx-auto p-4 sm:p-6">
        {/* Heading */}
        <h1 className="text-xl sm:text-2xl font-bold text-center text-gray-900 mb-4">
          Pravidla a podmínky používání
        </h1>
        {/* Tabs Navigation */}
        <div className="flex flex-col sm:flex-row border-b border-gray-300">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`w-full sm:w-auto px-4 sm:px-6 py-2 text-sm sm:text-base text-gray-600 font-semibold focus:outline-none ${
                activeTab === tab.id
                  ? "border-b-4 border-gray-500 text-gray-900 bg-white shadow-md"
                  : "hover:text-gray-900"
              } transition`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {/* Tab Content */}
        {activeTab === "terms" && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
              {/* Left Column */}
              <div className="flex flex-col space-y-3">
                {clausesLeft.map((clause, index) => (
                  <div
                    key={index}
                    className="bg-blue-100 text-gray-800 text-sm sm:text-base px-4 py-2 rounded-full shadow-sm w-fit"
                  >
                    {clause}
                  </div>
                ))}
              </div>

              {/* Right Column */}
              <div className="flex flex-col space-y-3">
                {clausesRight.map((clause, index) => (
                  <div
                    key={index}
                    className="bg-blue-100 text-gray-800 text-sm sm:text-base px-4 py-2 rounded-full shadow-sm w-fit"
                  >
                    {clause}
                  </div>
                ))}
              </div>
            </div>

            {/* Agreement & Definitions Section */}
            <div className="mt-8 p-4 sm:p-6 bg-gray-100 rounded-lg shadow-md">
              <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">
                Agreement
              </h2>
              <p className="text-gray-700 text-sm sm:text-base mb-4">
                By filling in and submitting the sign-up form, by checking boxes
                “I am over 18 years old or the Age of Majority in my region.”,
                “I have read, understood, and I accept the binding Agreement”
                and “I expressly declare the acceptance of Privacy Policy” and
                by clicking on the Create Account button, the Performer gives
                her(his)/its consent to, and notably express consent to the
                clauses regarding Declarations (4th clause) and Personal data
                (5th clause), and accepts the following.
              </p>

              <h2 className="text-lg sm:text-xl font-bold text-gray-900 mt-6 mb-2">
                Definitions
              </h2>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Performer”, “I”, “me”, “you”:</strong> Either Model,
                Studio, or both of them, as the case may be.
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Model”:</strong> A person providing adult entertainment
                Services for the Website Operator and who created an Account of
                the Model.
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Studio”:</strong> A person (legal or natural)
                subscribing to a Studio Account, and who is providing adult
                entertainment Services on its own or while contracting with
                Studio Models.
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Studio Model”:</strong> Model registered under an
                Account of a Studio and providing Services on the Studio’s
                behalf.
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Account”:</strong> A registration account for the Model
                and/or for the Studio on{" "}
                <a
                  href="https://www.modelosbonga.com"
                  className="text-blue-600 underline"
                >
                  www.bongamodels.com
                </a>
                .
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Websites”:</strong> Different websites, including{" "}
                <a
                  href="https://www.modelosbonga.com"
                  className="text-blue-600 underline"
                >
                  www.bongamodels.com
                </a>{" "}
                and any other website which might be operated by the Website
                Operator.
              </p>
              <p className="text-gray-800 text-sm sm:text-base">
                <strong>“Members”:</strong> Persons visiting and/or subscribing
                and/or buying the Services on the Websites or receiving any of
                its content via any media available now or created in the future
                (also referred to as “members” or “end users” from time to
                time).
              </p>
            </div>

            <div className="mt-8 p-4 sm:p-6 bg-gray-100 rounded-lg shadow-md">
              {/* Clause Header */}
              <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">
                1st clause — purpose
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Natural or legal Performers are acting under this Agreement as
                providers of professional Services in the frame of their
                business/professional activities when rendering their Services
                (not consumers).
              </p>

              {/* Model Section */}
              <h2 className="text-lg sm:text-xl font-bold text-gray-900 mt-6 mb-2">
                A. Model
              </h2>
              <p className="text-gray-800 text-sm sm:text-base">
                Section A is applicable only to Performers registered under a
                Model Account.
              </p>
              <p className="text-gray-800 text-sm sm:text-base mt-2">
                By means of the present Agreement, I expressly mark my interest
                to render Services as a Performer on the Websites promoted by
                the Website Operator and its affiliates, which currently manages
                a business that markets products/services to consumers (B2C),
                which may include but is not limited to interactive live
                streaming video chat site (based on the one available at{" "}
                <a
                  href="https://www.modelosbonga.com"
                  className="text-blue-600 underline"
                >
                  www.bongamodels.com
                </a>
                ) and broadcasting, according to the terms of this Agreement.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                The Performer undertakes not to provide any illegal, harmful,
                obscene, hateful, racial, ethnic, or any other objectionable
                content (such as defamatory, abusive, threatening, racially
                offensive, etc.) while rendering the Services under this
                Agreement.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                In order to improve the efficiency of the above-mentioned
                Services, I am authorized to use the Help Desk provided by
                Website Operator and/or Agent of Website Operator and/or its
                Services providers, whenever deemed convenient.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                I hereby certify that I work independently and that the Website
                Operator and/or Agent of Website Operator do not possess any
                power of direction, subordination, or other kind of authority
                over me. Consequently, the present Agreement does not determine
                my place or schedule for rendering the Services. The Website
                Operator and/or Agent of Website Operator do not tell me what my
                lawful content or my Services will be, since I am fully aware,
                as a Performer, that I must provide reliable and efficient
                Services in full compliance with the applicable legislation and
                object of the present Agreement.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                I hereby certify that I have read and understood the terms and
                conditions of the Websites that are applicable for Members of
                the Websites and I am aware of the level of quality and
                obligations that are implied in such terms and conditions. In
                the case of any questions to this regard, I can contact the
                Website Operator and/or Agent of Website Operator according to
                the contact details provided in this Agreement.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                Performers may render Services in one or more of the
                areas/categories available on the Websites, which are subject to
                change without prior notice from the Website Operator, with
                respect to standard requirements related to the Websites
                environment and consumer experience intended by the Website
                Operator.
              </p>

              <p className="text-gray-800 text-sm sm:text-base mt-2">
                The Services will be rendered by me with total autonomy and
                technical independence. Therefore, I am completely responsible
                and liable for my own actions, videos, audio, chat, dialogue,
                instructional videos, and advice, as a consenting responsible
                adult, and all decisions related to the content of my actions as
                a Performer.
              </p>
            </div>

            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                2nd clause — duration
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                This Agreement will be immediately effective on the date that
                the Performer fills in and submits the sign-up form, by checking
                boxes “I am over 18 years old or the Age of Majority in my
                region.”, “I have read, understood, and I accept the binding
                Agreement” and “I expressly declare the acceptance of Privacy
                Policy” and clicking on the Create Account button, and will
                remain in full force and effect until duly terminated by either
                party in full compliance with the terms and conditions expressly
                set forth in this Agreement.
              </p>
            </div>

            {/* Clause 3 - Fees */}
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                3rd clause — fees
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                I acknowledge that the amount to be paid for the Services
                rendered, within the scope of this Agreement, will be published
                on my registration Account. The Performer’s registration Account
                shows all the information related to the Performer’s income from
                the Services rendered, settlements with the Website Operator,
                statistics and other relevant information.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I understand and accept that the amounts to be paid by Website
                Operator or in accordance with its payment authorization by the
                Agent of the Website Operator or by the Payment Agent to me will
                correspond to a certain percentage (varying according to the
                type of Services) of the chosen charge option (i.e., certain
                percentage of the price paid by the Members of the Websites to
                the Website Operator) as described above which percentage and
                charge options will be published on my registration Account
                and/or my sales page (which I can consult before rendering any
                Services). I understand and accept that my compensation will be
                limited to the aforementioned payment and that no royalty will
                be due to me for any original content uploaded by me to the
                Websites or for any content of me which is broadcasted. I fully
                understand and acknowledge that the Members of the Websites are
                exclusively the customers of Website Operator.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I acknowledge that the payment period for the Services rendered
                is 7 days (from Monday to Sunday), with payments made every
                Friday for the previous week’s earnings with the following
                limitations: minimum payment amount is{" "}
                <strong>$50 for Models and $100 for Studios</strong>; if by the
                end of the payment period a Model’s/Studio’s income is less than
                the above-mentioned amount, their payment will be put on hold
                until the minimum payment amount is reached. I acknowledge that
                payments to be paid for the Services rendered are issued weekly
                via Paxum, Webmoney, and/or in cryptocurrencies Bitcoin,
                Ethereum and wire transfer, with an exception for models and
                studios located in the USA, having additional chance of getting
                payments issued via check and direct deposit.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I acknowledge that there is an early payment privilege on
                Tuesday instead of Friday for those Models and Studios who had
                fulfilled the special conditions as follows:
              </p>

              {/* Special Payment Condition */}
              <ul className="list-disc pl-5 text-gray-700 text-sm sm:text-base mt-2">
                <li>
                  The payment will be made on{" "}
                  <strong>Tuesday instead of Friday</strong> for the previous
                  week’s earnings to those Models (except Studio ones), who have
                  streamed at least during{" "}
                  <strong>3 hours both on Friday, Saturday and Sunday</strong>{" "}
                  (on each of the days specified) and their minimum payment
                  amount for the previous week is <strong>$50</strong>.
                </li>
              </ul>
            </div>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                4th clause — declarations
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                I hereby certify that I am, and as the case may be, that Studio
                Models under my Account as well as any person appearing in
                camera area or pictures, are at least 18 years of age (or 21
                when so required by local law), or age of majority in my/their
                legal jurisdiction (whichever is greater).
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I am fully aware and accept that the Website Operator will not
                be considered responsible, either jointly or severally, for any
                content, information, communication, opinion, expression, and/or
                action of any kind whatsoever which originate from the Members
                and/or Performers/Studio Models of the Websites or from any
                other person or third party to this Agreement.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I certify that all decisions related to the rendering of
                Services are made at my, or as the case may be, at Studio
                Models’ own discretion, that such Services do not violate the
                local community standard of ‘obscenity’ in my area, nor do they
                violate any other laws. I am completely responsible for my own
                actions as a consenting adult.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Moreover, I certify that I am not under the influence of drugs
                or alcohol, and that I am, and as the case may be, Studio Models
                are, rendering the Services/Studio Models Services in the
                video/film and appearance(s) at my/their own free will.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I will indemnify and hold the Website Operator harmless from all
                liabilities related to the Services rendered in the framework of
                this Agreement. I hereby discharge all persons related to the
                Website Operator of any past, present, or future liabilities in
                connection with this Agreement.
              </p>
            </div>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                5th clause — personal data
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                As a Performer, I hereby allow/will be responsible for ensuring
                that Studio Models will allow under the Studio Model Contract
                all my/their personal data and/or the contents published on the
                Websites to be inspected by the Website Operator and any
                entities appointed by the latter, randomly, resorting to any
                existing means for such effect.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Notwithstanding, I am aware that it is not a duty of the Website
                Operator to proceed with such inspection, and the Website
                Operator and said entities will not be considered responsible,
                either jointly or severally, in case of my/their unlawful
                conduct or provision of false information to the Website
                Operator.
              </p>
            </div>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                6th clause — rights to the image and author right
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                I expressly authorize, and as the case may be, I am responsible
                and liable for ensuring that Studio Models expressly authorize
                under the Studio Model Contract, the Website Operator to
                monitor, record, and log all my/Studio Model online activities
                (including chat, video, e-mail, etc.) on the Websites (including
                but not limited to{" "}
                <a
                  href="https://www.modelosbonga.com"
                  className="text-blue-600 underline"
                >
                  www.bongamodels.com
                </a>
                ).
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I acknowledge and agree, and as the case may be, I am
                responsible and liable for ensuring that Studio Models are
                informed that the usage of photo, video, audio, text, or other
                content of the third-party during the rendering of Services
                requires necessary written permissions and/or licenses for
                commercial use and that acquiring such permissions is the sole
                responsibility of the Model/Studio Model.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Furthermore, I am aware that Website Operator can at any time at
                their own discretion request from Model/Studio Model such
                documents as evidence of having any and all necessary
                permissions, licenses, or similar documents.
              </p>
            </div>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                7th clause — duties
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                I undertake the duty to provide the Website Operator and Agent
                of Website Operator, in writing, with updated information
                concerning any changes related to my/Studio Models’ personal
                data, within five days from the occurrence/notification of said
                changes.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I undertake, and as the case may be, I am responsible and liable
                that Studio Models undertake the duty to renew expired documents
                and to provide such documents to the Website Operator. I
                understand that without providing valid documents to the Website
                Operator, I will not be allowed to use the services of the
                website to the full extent.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I also undertake the duty to possess and provide at my own costs
                (to Studio Models as the case may be) the working instruments
                (including IT tools) necessary for the efficient rendering of
                the Services/Studio Models Services established in this
                Agreement and/or the Studio Model Contract.
              </p>
            </div>
            {/* Clause 8 - Basic Rules */}
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                8th clause — basic rules for the provision of services
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                The below-mentioned rules must be followed by all Performers
                rendering Services through the Websites. The Studios undertake
                to guarantee that Studio Models rendering Services on their
                behalf respect the rules below as well.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Performers must have a valid and approved registration on the
                Websites. Performer, or as the case may be, Studio Model
                appearing in the camera area or in the pictures through
                bongamodels.com must be registered under the given Account.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Broadcasting a pre-recorded video or still image instead of live
                camera stream is against the rules of the Websites. Violation of
                the rules is considered a breach of this Agreement and might
                result in monetary deduction, suspension of the Account,
                immediate ban from the Websites or the discontinuance of
                payouts.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                Giving out and asking for personal information, including
                personal information of Members and Performer or Studio Models
                is against the rules. E-mail addresses from outside the
                Websites, online messenger IDs (such as: Yahoo!, MSN, ICQ,
                Viber, Skype, etc.), permanent addresses, etc. are considered
                personal information and are forbidden to be asked and/or
                provided.
              </p>
            </div>
            <ViolationsTable />
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md mb-6">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                9th clause — assignment
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                I am not entitled to assign or transfer my contractual position
                to any other person/entity. I agree, and as the case may be, I
                will be responsible and liable for ensuring that Studio Models
                agree, that my/Studio Models username/password must not be
                provided to any other person, otherwise I will be held liable in
                accordance with Clause 7 and 8 of this Agreement.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                I agree that the Website Operator has the right to transfer or
                assign its rights and obligations under this Agreement without
                limitation.
              </p>
            </div>

            {/* Clause 10 - Amendments */}
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md mb-6">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                10th clause — amendments
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                The Website Operator may make changes to this Agreement, but
                undertakes the duty to inform me of such changes before they
                enter into force. In case the Website Operator makes changes to
                this Agreement, the Studio undertakes the duty to reflect such
                changes in the Studio Model Contract, to ensure that the Studio
                Model Contract remains compliant with the rights and obligations
                of the Studio under the Agreement.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                The last revision of this Agreement is indicated at the top of
                this document and takes precedence over all previous versions.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                If any modification is unacceptable to you, you or as the case
                may be, Studio Models may cease providing Services to the
                Website Operator or terminate this Agreement. If you, or as the
                case may be, Studio Models do not cease rendering Services, you
                will be conclusively deemed to have accepted the changes.
              </p>
              <p className="text-gray-700 text-sm sm:text-base mt-4">
                In case of any queries, I may contact{" "}
                <a
                  href="mailto:support@bongamodels.com"
                  className="text-blue-600 underline"
                >
                  support@bongamodels.com
                </a>{" "}
                directly. I hereby acknowledge that the terms and conditions
                (including its privacy policy) of the Agreement may be consulted
                by me at any time by visiting the{" "}
                <a href="#" className="text-blue-600 underline">
                  following page
                </a>
                .
              </p>
            </div>

            {/* Clause 11 - Termination */}
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                11th clause — termination
              </h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Either party may terminate this Agreement, at any time, for any
                or no reason, upon prior and express notice given in writing
                (including via email) to the other party with a minimum
                antecedence of five business days. The Website Operator may
                terminate this Agreement immediately in the case of serious
                violation of its terms by the counterparty, without prior
                notice.
              </p>
            </div>
          </>
        )}
        {activeTab === "privacy" && (
          <div className="bg-white p-6 rounded-lg shadow-md mt-6">
            <h2 className="text-gray-800 font-semibold">
              LAST REVISION: 22.11.2024
            </h2>
            <p className="text-gray-700 mt-2">
              Please read this BongaModels Privacy Policy carefully. It sets
              forth the practices of
              <strong> BNC TECHNOLOGY AND ADVERTISING LTD </strong> (“BNC
              TECHNOLOGY AND ADVERTISING LTD” and/or “Company”) with respect to
              information collected from Visitors, Models, Studio Models, and
              Studios of bongamodels.com with respect to all services provided
              by the company or services requiring a log-in or password (the
              “Services”).
            </p>
            <p className="text-gray-700 mt-4">
              The Company does not knowingly collect personal data from
              children. If you are under the age of eighteen (18) or the age of
              majority in your jurisdiction (Age of Majority), please do not
              give us any personal data. If you have reason to believe that
              anyone under the Age of Majority has provided personal data to the
              Company, please contact us, and we will endeavor to delete that
              information from our databases.
            </p>

            <h3 className="text-lg font-bold text-gray-900 mt-6">
              DATA PROTECTION
            </h3>
            <p className="text-gray-700 mt-2">
              The subject of data protection is personal data. According to Art.
              4 (1) GDPR (General Data Protection Regulation), all information
              relating to an identified or identifiable natural person is
              considered as Personal Data.
            </p>

            <h3 className="text-lg font-bold text-gray-900 mt-6">
              Data Controller
            </h3>
            <p className="text-gray-700 mt-2">
              The data controller of your personal data is{" "}
              <strong>BNC TECHNOLOGY AND ADVERTISING LTD</strong>, Digeni
              Akrita, 8, Office 202, 1045 Nicosia, Cyprus.
            </p>

            <h3 className="text-lg font-bold text-gray-900 mt-6">
              Data Protection Officer
            </h3>
            <p className="text-gray-700 mt-2">
              If you have any questions regarding data protection, you can
              contact our data protection officer via email:{" "}
              <a
                href="mailto:modelosbonga@gmail.com"
                className="text-blue-600 underline"
              >
                dpo@bongamodels.com
              </a>
              .
            </p>
          </div>
        )}
        {activeTab === "cookies" && (
          <div className="bg-white p-6 rounded-lg shadow-md mt-6">
            <h2 className="text-gray-900 font-bold text-xl">Cookies</h2>
            <h3 className="text-gray-700 mt-2 font-semibold">
              LAST REVISION: 22.11.2024
            </h3>
            <h3 className="text-lg font-bold text-gray-900 mt-6">
              General Information About Cookies
            </h3>
            <p className="text-gray-700 mt-2">
              We use cookies on our website. These are small files that are
              automatically created by your browser and stored on your device
              when you visit our website.
            </p>
            <p className="text-gray-700 mt-2">
              The use of cookies serves to make the use of our website more
              pleasant for you. We use session cookies to recognize that you
              have already visited individual pages of our website.
            </p>
            <h3 className="text-lg font-bold text-gray-900 mt-6">
              Legal Basis for the Use of Cookies
            </h3>
            <p className="text-gray-700 mt-2">
              The legal basis for the processing of personal data using cookies
              for analysis purposes is based on the consent given by the user —
              Art. 6 (1) (a) GDPR.
            </p>
            <p className="text-gray-700 mt-2">
              The legal basis for the processing of personal data using
              technically necessary cookies is Art. 6 (1) (f) GDPR.
            </p>
            <div className="overflow-x-auto mt-4">
              <table className="w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-blue-200 text-gray-800">
                    <th className="p-2 text-left border border-gray-300">
                      Name
                    </th>
                    <th className="p-2 text-left border border-gray-300">
                      Runtime
                    </th>
                    <th className="p-2 text-left border border-gray-300">
                      Purpose
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    ["bonga20120608", "Session", "Session cookie"],
                    [
                      "ts_type2",
                      "1 year",
                      "Used for tracking of the source type",
                    ],
                    ["rmn", "180 days", "“Remember Me” cookie"],
                    ["_saf_", "Session", "Used for 2-step-authentication"],
                    [
                      "pwai",
                      "30 days",
                      "Used to determine if Progressive web app (PWA) needs to be proposed",
                    ],
                    ["cbst", "Session", "Used to hide 'Fan boost' alert"],
                    [
                      "wfmc",
                      "Session",
                      "Saves currently displayed 'Have a good stream' notification",
                    ],
                    [
                      "cqpv",
                      "1 day",
                      "Used for alerts with the broadcast information",
                    ],
                    [
                      "bm_cfs",
                      "Session",
                      "Placed if the panel with floating dialogs is closed",
                    ],
                    [
                      "epc",
                      "Session",
                      "Ensures that next pop-up regarding exiting the website will be shown no sooner than 10 minutes after closing one",
                    ],
                  ].map(([name, runtime, purpose], index) => (
                    <tr key={index} className="border border-gray-300">
                      <td className="p-2 border border-gray-300">{name}</td>
                      <td className="p-2 border border-gray-300">{runtime}</td>
                      <td className="p-2 border border-gray-300">{purpose}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>{" "}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
