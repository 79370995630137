import React from "react";

const ViolationsTable = () => {
  const violations = [
    {
      description: "Leaving the camera image (being out of camera view)",
      fine: "$25.00",
    },
    {
      description: "Broadcasting alone on couple accounts in Free chat",
      fine: "$25.00",
    },
    {
      description: "Sleeping or passing out (Performer or/and partner)",
      fine: "$25.00",
    },
    {
      description:
        "Exchanging, giving out, asking for or accepting personal/contact information (On such proposals, the Performer should refuse to provide/accept personal information or ignore the request. The answer, which does not contain a direct refusal, for example, “Later, I can’t now” — will be considered as a violation of the rule).",
      fine: "$25.00",
    },
    { description: "Misleading about gender", fine: "$25.00" },
    { description: "Animals/pets in the broadcast area", fine: "$25.00" },
    {
      description: "Publishing of links to other Internet resources",
      fine: "$25.00",
    },
    {
      description:
        "Sending own referral link to BongaCams members/guests in Free/Private Chat (it is prohibited to spam by own referral link)",
      fine: "$25.00",
    },
    {
      description:
        "Referring to other live adult websites by text material, logos, pictures, videos etc.",
      fine: "$50.00",
    },
    {
      description:
        "Asking for and/or receipt of the payment from the members outside the website (without the provision of the requisites)",
      fine: "$50.00",
    },
    {
      description:
        "Asking for and/or receipt of the payment from the members outside the website with the provision of the requisites",
      fine: "$100.00",
    },
    {
      description:
        "Referring, advertising, propaganda and/or promotion of other live adult websites by Performer by sending/ broadcasting or posting their links on the website",
      fine: "$100.00",
    },
  ];

  return (
    <div className="max-w-5xl mx-auto p-4 sm:p-6 bg-blue-100 rounded-lg shadow-lg">
      <div className="overflow-hidden">
        <div className="divide-y divide-blue-200">
          {violations.map((violation, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row justify-between items-start sm:items-center p-3 bg-blue-50 rounded-lg mb-2"
            >
              <p className="text-gray-800 text-sm sm:text-base">
                {violation.description}
              </p>
              <span className="text-gray-900 font-semibold text-sm sm:text-base mt-2 sm:mt-0">
                {violation.fine}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViolationsTable;
