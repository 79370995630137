import React, { useState } from "react";
import { ChevronDown, LogOut, Menu, X } from "lucide-react";
import {
  FaTelegramPlane,
  FaInstagram,
  FaTimes,
  FaCommentDots,
} from "react-icons/fa";
import Flag from "../assets/Spain_flag_300.png";
import image from "../assets/logo2.png";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import ContactUs from "./ContactUs";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/terms");
  };
  const handleFaq = () => {
    navigate("/faq");
  };
  const handleContact = () => {
    navigate("/contáctanos");
  };
  const handleComplaince = () => {
    navigate("/2257");
  };
  return (
    <div>
      <ContactUs isOpen={isFormOpen} setIsOpen={setIsFormOpen} />

      <nav className="bg-[#6C133B] relative z-10  hidden md:block">
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          {/* Left Section - LIVE indicator */}
          <div className="flex items-center space-x-2">
            <div className="flex items-center bg-green-500 rounded-full w-3 h-3"></div>
            <span className="text-white font-semibold text-sm">2793 LIVE</span>
          </div>

          {/* Center Section - Navigation Links */}
          <ul className="hidden md:flex space-x-8">
            {[
              { label: "Términos", path: "/terms" },
              { label: "Preguntas frecuentes", path: "/faq" },
              { label: "Registro", path: "/registro", isForm: true }, // Added isForm flag
              { label: "Contáctanos", path: "/contáctanos" },
            ].map((item) => (
              <li key={item.label}>
                <button
                  onClick={() =>
                    item.isForm ? setIsFormOpen(true) : navigate(item.path)
                  } // Check if it's "Registro"
                  className="text-white font-medium text-sm hover:underline hover:text-gray-200 transition"
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>

          {/* Right Section - Login and Language Selector */}
          <div className="flex items-center space-x-6">
            <a
              href="#"
              className="text-white font-medium text-sm hover:underline hover:text-gray-200 transition"
            >
              Entrar
            </a>
            {/* Language Selector */}
            <div className="flex items-center space-x-2 text-white">
              <img src={Flag} alt="ES Flag" className="w-5 h-5 object-cover" />
              <span className="text-sm">ES</span>
              <ChevronDown size={16} />
            </div>
          </div>
        </div>
      </nav>

      {/* Lower Navbar Section */}
      <div className="bg-[#6b0129] hidden md:block">
        <div className="overflow-hidden bg-gradient-to-b from-[#6B0129] to-[#6B0129] clip-mountain-down absolute top-0 left-0 w-24 h-24"></div>
        <div className="container mx-auto flex justify-end items-center h-full px-4 text-white relative">
          {/* Right Side (Logo and Branding) */}
          <Link to="/">
            <div className="flex flex-col items-center ">
              <img src={image} alt="Logo" className="w-62 h-8" />
              <div className="text-[9px] font-medium text-center mb-2">
                <p>Gana Dinero y Diviértete</p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* Mobile Menu (Sliding Sidebar) */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed top-0 left-0 w-4/5 h-full bg-white shadow-lg p-6 overflow-y-auto">
            {/* Close Button */}
            <button
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-4 right-4 text-gray-700"
            >
              <X size={24} />
            </button>

            {/* Language Selector at the Top */}
            <div className="flex items-center space-x-2 mb-6">
              <img src={Flag} alt="ES Flag" className="w-5 h-5 object-cover" />
              <span className="text-gray-800 font-semibold">ESPAÑOL</span>
              <ChevronDown size={16} className="text-gray-800" />
            </div>

            {/* Menu Links (Styled like the image) */}
            <ul className="space-y-2 text-gray-800 font-medium text-lg">
              <li>
                <button
                  onClick={() => setIsFormOpen(true)}
                  className="w-full text-left px-4 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 transition"
                >
                  REGISTRO DE MODELO
                </button>
              </li>

              <li>
                <button
                  onClick={handleContact}
                  className="w-full text-left px-4 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 transition"
                >
                  CONTACTAR SOPORTE
                </button>
              </li>
              <li>
                <button
                  onClick={handleFaq}
                  className="w-full text-left px-4 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 transition"
                >
                  PREGUNTAS FRECUENTES
                </button>
              </li>
            </ul>

            {/* Full Version Button */}

            {/* Disclaimer Section */}
            <div className="mt-6 text-gray-600 text-sm">
              <p>
                Exención de responsabilidad: Todos los usuarios que emiten en
                esta web nos han proporcionado por contrato confirmación de ser
                mayores de 18 años.
              </p>
              <a
                onClick={handleComplaince}
                className="text-blue-600 hover:underline inline-block mt-2 cursor-pointer"
              >
                18 U.S.C. 2257 Mantenimiento de Registros de Declaración de
                cumplimiento de Requisitos
              </a>
            </div>

            {/* Footer Links */}
            {/* <div className="mt-4 flex flex-wrap text-gray-700 text-sm gap-4">
              <a
                onClick={handleNavigation}
                href="#"
                className="hover:underline"
              >
                Términos
              </a>
              <a href="#" className="hover:underline">
                Privacidad
              </a>
              <a href="#" className="hover:underline">
                Cookies
              </a>
              <a href="#" className="hover:underline">
                Estado
              </a>
            </div> */}

            {/* Social Icons (With "NEW" labels) */}
            {/* <div className="mt-6 flex gap-4">
              <a
                href="#"
                className="text-[#fff] bg-[#660345] rounded-full p-2 hover:bg-[#5a023a] transition"
              >
                <FaTelegramPlane size={18} />
              </a>
              <a
                href="#"
                className="text-[#fff] bg-[#660345] rounded-full p-2 hover:bg-[#5a023a] transition"
              >
                <FaInstagram size={18} />
              </a>
              <a
                href="#"
                className="text-[#fff] bg-[#660345] rounded-full p-2 hover:bg-[#5a023a] transition"
              >
                <FaTimes size={18} />
              </a>
              <a
                href="#"
                className="relative text-[#fff] bg-[#660345] rounded-full p-2 hover:bg-[#5a023a] transition"
              >
                <FaCommentDots size={18} />
                <span className="absolute -top-1 -right-1 bg-pink-500 text-xs text-white font-semibold px-1.5 py-0.5 rounded-full">
                  NEW
                </span>
              </a>
            </div> */}
          </div>
        </div>
      )}

      {/* Lower Navbar Section */}
      <div className="bg-[#6b0129] md:hidden">
        {" "}
        {/* Hide on desktop */}
        <div className="container mx-auto flex justify-between items-center py-3 px-4 text-white relative">
          {/* Hamburger Menu for Mobile */}
          <button
            onClick={() => setIsMenuOpen(true)}
            className="text-white focus:outline-none"
          >
            <Menu size={28} />
          </button>
          {/* Logo and Branding (Centered) */}
          <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center">
            <Link to="/">
              <img src={image} alt="Logo" className="w-32 h-6" />
              <div className="text-[8px] font-medium text-center">
                <p>Gana Dinero y Diviértete</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
