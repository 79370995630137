import Footer from "./Footer";
import Navbar from "./Navbar";
import React, { useState } from "react";
import { ArrowLeft } from "lucide-react";

const ComplianceNotice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleBackClick = () => {
    setIsLoading(true);
    window.location.href = "/";
  };
  return (
    <div>
      <Navbar />
      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
          <div className="w-12 h-12 border-4 border-[#6b0129] border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}

      {/* Back Button - Visible on Mobile Only */}
      <button
        onClick={handleBackClick}
        className="absolute top-2 left-4 bg-[#6b0129] text-white p-2 rounded-full shadow-md hover:bg-[#6b0129] transition md:hidden"
      >
        <ArrowLeft size={20} />
      </button>
      <div className="max-w-4xl mx-auto bg-white p-6 sm:p-8 rounded-lg shadow-lg border border-gray-200 mt-10 mb-10">
        {/* Title */}
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          18 U.S.C. 2257 Compliance Notice
        </h2>

        {/* Description */}
        <p className="text-gray-700 mb-4 leading-relaxed">
          In compliance with the Federal Labeling and Record-Keeping Law (also
          known as
          <strong> 18 U.S.C. 2257</strong>), all models located within our
          domain were
          <strong> 18 years of age or older</strong> during the time of
          photography. All models' proof of age is held by the custodian of
          records, which is listed below. All content and images are in full
          compliance with the requirements of 18 U.S.C. 2257 and associated
          regulations.
        </p>

        {/* Company Details */}
        <div className="text-gray-800 font-medium">
          <p>BNC TECHNOLOGY AND ADVERTISING LTD</p>
          <p>Digeni Akrita, 8, Office 202,</p>
          <p>1045 Nicosia, Cyprus</p>
        </div>

        {/* Contact Email */}
      </div>
      <Footer />
    </div>
  );
};

export default ComplianceNotice;
