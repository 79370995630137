import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import image from "../assets/chat_free.webp";
import image2 from "../assets/img.avif";
import image3 from "../assets/img (1).avif";
import image4 from "../assets/img (2).avif";
import image5 from "../assets/img (3).avif";
import image6 from "../assets/img (4).avif";
import image7 from "../assets/img (5).avif";
import image8 from "../assets/img (6).avif";
import image9 from "../assets/img (7).avif";
import image10 from "../assets/img (8).avif";
import image11 from "../assets/img (9).avif";
import image12 from "../assets/img (10).avif";
import image13 from "../assets/img (11).avif";
import image14 from "../assets/img (12).avif";
import image15 from "../assets/img (13).avif";
import image16 from "../assets/img (14).avif";
import image17 from "../assets/img (15).avif";
import image18 from "../assets/img (16).avif";
import image19 from "../assets/img (17).avif";
import image20 from "../assets/img (18).avif";
import image21 from "../assets/img (19).avif";
import image22 from "../assets/img (20).avif";
import image23 from "../assets/img (21).avif";
import image24 from "../assets/img (22).avif";
import image25 from "../assets/img (23).avif";
import image26 from "../assets/img (24).avif";
import image27 from "../assets/img (25).avif";
import { ArrowLeft } from "lucide-react";

const FAQ = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleBackClick = () => {
    setIsLoading(true);
    window.location.href = "/";
  };

  const faqs = [
    {
      category: "Ganancias en BongaModels",
      items: [
        { question: "Cómo ganar dinero con BongaModels" },
        { question: "Chat gratuito" },
        { question: "Chat privado" },
        { question: "Chat privado completo" },
        { question: "Modo espía" },
        { question: "Chat grupal" },
        { question: "Propinas" },
        { question: "¿Cómo puedo aumentar mis ingresos?" },
        { question: "¿Cuánto cuestan los chats de pago?" },
        {
          question:
            "¿Cómo establecer precios personalizados para los chats de pago?",
        },
        { question: "Pagos" },
      ],
    },
    {
      category: "Chatbots y aplicaciones",
      items: [
        {
          question: "¿Cómo descargo e instalo la aplicación móvil BongaModels?",
        },
        { question: "Cuentas regresivas" },
        { question: "¿Cómo transmitir mediante OBS?" },
        { question: "Tuitear en BongaCams" },
        { question: "¿Puedo conectar Lovense durante las retransmisiones?" },
        { question: "¿Cómo puedo activar la visualización del menú Lovense?" },
        { question: "¿Qué son los chatbots?" },
        { question: "¿Cómo incluyo un chatbot en mi sala de chat?" },
        { question: "¿Cómo configuro un chatbot?" },
        {
          question:
            "¿Cómo cambiar los ajustes del chatbot sin interrumpir el stream?",
        },
        { question: "¿Cómo desactivo un chatbot?" },
      ],
    },
    {
      category: "Preguntas técnicas", // ✅ New Section Added Below "Configuración y seguridad de la cuenta"
      items: [
        {
          question:
            "¿Qué software y velocidad de Internet debo tener para hacer streaming en un ordenador?",
        },
        {
          question:
            "No puede ver mi retransmisión. ¿Cuál podría ser el problema?",
        },
        {
          question: "Puedo ver mi vídeo, pero el sonido no funciona. ¿Por qué?",
        },
        { question: "¿Qué debo hacer si mi stream es lento?" },
        { question: "¿Es posible bloquear determinados países y ciudades?" },
        { question: "¿Puedo retransmitir por el chat un vídeo ya grabado?" },
        {
          question:
            "¿Por qué no he recibido un correo electrónico de confirmación o recuperación de contraseña?",
        },
        { question: "¿Cómo puedo cambiar la dirección de correo electrónico?" },
        { question: "¿Cómo añado enlaces de redes sociales a mi perfil?" },
        {
          question:
            "¿Cómo puedo desactivar la visualización de las instantáneas recientes de la webcam?",
        },
      ],
    },

    {
      category: "Ingresos y pagos",
      items: [
        { question: "¿Qué formas de pago hay disponibles?" },
        { question: "¿Cómo puedo cambiar la forma de pago?" },
        { question: "¿Con qué frecuencia se realizan los pagos?" },
        { question: "¿Puedo recibir pagos en otro momento?" },
        { question: "¿Cómo puedo recibir un pago antes de lo previsto?" },
        {
          question:
            "¿Por qué el importe que he ganado no aparece en mi saldo los lunes?",
        },
        { question: "¿Quién tiene derecho a los pagos bajo petición?" },
        {
          question:
            "¿Qué métodos de pago están disponibles para el pago bajo petición?",
        },
        {
          question:
            "¿Cuál es el importe mínimo y máximo de pago bajo petición?",
        },
        { question: "¿Por qué no están disponibles los pagos bajo petición?" },
        {
          question:
            "¿Cómo funciona el programa de referidos para atraer modelos y estudios?",
        },
      ],
    },
    {
      category: "Configuración y seguridad de la cuenta",
      items: [
        { question: "¿Cómo puedo eliminar mi cuenta?" },
        {
          question:
            "¿Cómo desactivar la verificación en 2 pasos sin acceso al número?",
        },
        {
          question:
            "¿Qué enlaces a redes sociales están permitidos en el sitio?",
        },
        {
          question:
            "¿Por qué no puedo adjuntar un enlace en asistencia jurídica?",
        },
        { question: "¿Cuánto tiempo se tarda en eliminar un video ajeno?" },
      ],
    },
    {
      category: "Cómo empezar a trabajar",
      items: [
        { question: "Registro" },
        { question: "¿Qué se necesita para empezar?" },
        { question: "Retransmisiones" },
        { question: "Navegación por la web" },
        {
          question:
            "¿Cuánto dura el período de promoción de nuevos o nuevas modelos?",
        },
      ],
    },

    {
      category: "Preguntas adicionales",
      items: [
        { question: "¿Quién puede ser modelo webcam en BongaCams?" },
        { question: "¿Cuándo puedo empezar?" },
        { question: "¿Tendré un horario de trabajo obligatorio?" },
        {
          question:
            "¿Puedo promocionar mis cuentas de redes sociales en mi perfil de BongaCams?",
        },
        { question: "¿A quién puedo hacer más preguntas?" },
      ],
    },
    {
      category: "Valoración y popularidad",
      items: [
        { question: "¿Qué es el CamScore?" },
        { question: "¿Por qué necesitas una valoración CamScore?" },
        { question: "¿Cómo se calcula CamScore?" },
        { question: "¿Cómo puedo mejorar mi valoración?" },
        { question: "Valoración de las nuevas modelos" },
      ],
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="max-w-6xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg">
        {isLoading && (
          <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
            <div className="w-12 h-12 border-4 border-[#6b0129] border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
        <button
          onClick={handleBackClick}
          className="absolute top-2 left-4 bg-[#6b0129] text-white p-2 rounded-full shadow-md hover:bg-[#6b0129] transition md:hidden"
        >
          <ArrowLeft size={20} />
        </button>

        {/* Header */}
        <h1 className="text-2xl font-bold text-center text-gray-900 mb-6">
          Preguntas Frecuentes
        </h1>

        {/* Search Bar */}
        <div className="flex items-center bg-gray-100 p-3 rounded-md shadow-sm mb-6">
          <input
            type="text"
            placeholder="Buscar pregunta..."
            className="w-full bg-transparent outline-none text-gray-700"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="text-gray-500">🔍</span>
        </div>

        {/* FAQ Content - Grid Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqs.map((faq, catIndex) => (
            <div key={catIndex}>
              {/* Category Title */}
              <h2 className="text-lg font-bold text-[#9B1D56] mb-4">
                {faq.category}
              </h2>

              {/* Questions List */}
              <div className="flex flex-col space-y-2">
                {faq.items
                  .filter((item) =>
                    item.question.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((item, i) => (
                    <div key={i} className="relative">
                      {/* Clickable Question Bubble */}
                      <div className="bg-blue-100 text-gray-800 px-4 py-2 rounded-full shadow-sm w-fit cursor-pointer hover:bg-blue-200 transition">
                        {item.question}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className=" mt-10">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
            Cómo ganar dinero con BongaModels
          </h2>

          <p className="text-gray-700 mb-6 leading-relaxed">
            Puedes ganar dinero tanto en chats gratuitos como privados. Aplica
            nuestros consejos para aumentar tus ganancias en BongaModels.
          </p>

          <ol className="list-decimal list-inside space-y-3 text-gray-800">
            <li>
              Utiliza <strong>«Tema»</strong> o{" "}
              <strong>«Bot de menú de propinas»</strong> para notificar a los
              usuarios el precio de tus servicios.
            </li>
            <li>
              Sonríe e intenta transmitir buen humor. Los usuarios prefieren
              modelos positivos.
            </li>
            <li>
              Antes de irte, comunica cuándo harás tu próximo stream usando la
              función <strong>«Notificación»</strong>.
            </li>
            <li>
              Enciende el micrófono para interactuar con los usuarios; la voz es
              más atractiva que solo texto.
            </li>
            <li>
              Llama a los usuarios por sus apodos o nombres, crea una conexión
              personal.
            </li>
            <li>
              Interactúa con varios usuarios a la vez, priorizando aquellos que
              podrían dar propinas o pagar chats privados.
            </li>
            <li>
              Si un usuario te envía Tokens o invita a un chat privado,
              inclúyelo en tu lista de seguidores.
            </li>
            <li>
              Usa colores vibrantes y maquillaje llamativo para destacar en la
              pantalla.
            </li>
            <li>
              Haz pequeños espectáculos, bromas o atuendos divertidos para
              mantener la atención de los usuarios.
            </li>
            <li>
              Ten a mano juguetes y accesorios para animar la transmisión.
            </li>
            <li>
              Responde de forma intrigante si te preguntan sobre tu vida
              personal, como si tienes pareja.
            </li>
            <li>
              Las <strong>propinas</strong> son una forma clave de generar
              ingresos. Puedes ganar mucho sin necesidad de hacer espectáculos
              privados.
            </li>
            <li>
              No te desanimes si los ingresos son bajos al principio; la
              constancia es clave para construir una audiencia fiel.
            </li>
          </ol>
        </div>
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-gray-900">Chat gratuito</h2>
          <p className="text-gray-700 leading-relaxed">
            Si vas a ganar dinero haciendo retransmisiones en un chat gratuito,
            la mejor estrategia es atraer al mayor número de usuarios posible.
            El chat es gratuito, por lo que sólo ganarás si los usuarios te dan
            propina. La cantidad de la propina suele depender del espectáculo de
            la modelo; incluso un poco de provocación y coqueteo te ayudarán a
            ganar más Tokens. Además del chat gratuito, puedes ganar dinero en
            chats privados y grupales.
          </p>
          <img
            src={image} // Replace with the actual image URL
            alt="Chat Gratuito"
            className="w-40 h-auto rounded-md shadow-md"
          />
        </div>

        {/* Chat Privado Section */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900">Chat privado</h2>
          <p className="text-gray-700 leading-relaxed">
            Un usuario con Tokens puede invitar a una modelo a un chat privado,
            para ello debe hacer clic en el botón «Chat privado» de la sala de
            chat. La modelo decide si acepta o rechaza la invitación. Si la
            modelo acepta la invitación, comienza el espectáculo privado.
          </p>
          <p className="text-gray-700 leading-relaxed">
            La modelo fija el precio de los chats en grupo desde los ajustes de
            la consola antes de iniciar la emisión. El precio por defecto es de
            60 Tokens por minuto. Por ejemplo, si un usuario pasa 10 minutos en
            un chat privado, el/la modelo gana 600 Tokens (10 minutos × 60
            Tokens).
          </p>
          <p className="text-gray-700 leading-relaxed">
            Los chats privados se cierran automáticamente si el usuario se queda
            sin Tokens. El modelo o el usuario también pueden finalizar el chat
            en cualquier momento.
          </p>
          <img
            src={image} // Replace with the actual image URL
            alt="Chat Privado"
            className="w-40 h-auto rounded-md shadow-md"
          />
        </div>

        {/* New Section: Chat Grupal */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900">Chat grupal</h2>
          <p className="text-gray-700 leading-relaxed">
            El chat grupal permite que varios usuarios paguen para unirse a una
            transmisión privada al mismo tiempo. Esto te ayuda a maximizar tus
            ingresos, ya que recibes tokens de múltiples usuarios durante una
            sola sesión.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Puedes establecer una tarifa fija por usuario para unirse al chat
            grupal, lo que facilita la gestión de ingresos y ofrece una
            experiencia exclusiva para los participantes.
          </p>
          <img
            src={image} // Replace with the actual image URL
            alt="Chat Grupal"
            className="w-40 h-auto rounded-md shadow-md"
          />
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900">
            Propinas y Recompensas
          </h2>
          <p className="text-gray-700 leading-relaxed">
            Las propinas son una excelente manera de aumentar tus ingresos
            durante las transmisiones en vivo. Los usuarios pueden enviarte
            tokens como muestra de agradecimiento por tu contenido.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Puedes incentivar a los espectadores ofreciendo recompensas
            especiales a quienes envíen propinas, como saludos personalizados,
            acceso a contenido exclusivo o interacción directa durante el chat.
          </p>

          <div className="flex justify-start">
            <img
              src={image} // Replace with the actual image URL
              alt="Propinas y Recompensas"
              className="w-40 h-auto rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900">
            Interacción con los Usuarios
          </h2>

          <p className="text-gray-700 leading-relaxed">
            La forma en que interactúas con los usuarios puede marcar la
            diferencia en tus ingresos. Mostrar interés genuino en sus
            comentarios, hacer preguntas y mantener una conversación activa
            puede ayudar a fidelizar a los espectadores.
          </p>

          <p className="text-gray-700 leading-relaxed">
            Recuerda personalizar las interacciones, mencionando sus nombres o
            agradeciéndoles por su participación. Esto genera un vínculo
            emocional que los motiva a apoyarte con propinas o suscripciones.
          </p>

          <div className="flex justify-start">
            <img
              src={image2} // Replace with actual image URL
              alt="Interacción con los Usuarios"
              className="w-40 h-auto rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ropa de mujer
          </h2>
          <p className="text-gray-700 mb-4">
            Intenta ir elegante y elige prendas que te hagan sexy. Recuerda que
            el conjunto ideal es el que realza los atributos más destacados de
            tu cuerpo, oculta los defectos e te inspira confianza.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image3}
              alt="Ropa de Mujer 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image4}
              alt="Ropa de Mujer 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image5}
              alt="Ropa de Mujer 3"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
          <p className="text-gray-700 mt-4">
            Solo tú decides qué look es el más adecuado y el que te sienta
            mejor. Elige la ropa y los complementos que más te gusten. El sitio
            cuenta con muchísimos usuarios, siempre va a haber alguien que
            apreciará tu creatividad y singularidad.
          </p>
        </div>

        {/* Existing Section: Ropa de Hombre */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ropa de hombre
          </h2>
          <p className="text-gray-700 mb-4">
            Los hombres, como las mujeres, deben mostrar su individualidad y ser
            creativos a la hora de elegir su ropa. Esfuérzate para crear algo
            que sea único para los usuarios del chat.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image6}
              alt="Ropa de Hombre 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image7}
              alt="Ropa de Hombre 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image8}
              alt="Ropa de Hombre 3"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Maquillaje</h2>
          <p className="text-gray-700 mb-4">
            Por regla general, el rostro parece menos expresivo en la pantalla
            que en la vida real, por lo que recomendamos utilizar tonos más
            oscuros y más brillantes cuando se hace streaming.
          </p>
          <p className="text-gray-700 mb-4">
            Unos cosméticos bien seleccionados resaltarán con éxito todos los
            atributos más destacados de tu aspecto. En función de lo que
            quieras, puedes cambiar radicalmente tu aspecto: haz que tus ojos
            sean más expresivos y tus labios más brillantes. Como resultado,
            ganarás confianza en ti mismo/a. Sé creativo/a: utiliza todos los
            medios disponibles para crear el aspecto deseado.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image9}
              alt="Maquillaje 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image10}
              alt="Maquillaje 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image11}
              alt="Maquillaje 3"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>

        {/* Existing Section: Manicura y Pedicura */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Manicura y pedicura
          </h2>
          <p className="text-gray-700 mb-4">
            Recuerda que no puedes ocultar nada a la cámara, así que debes
            cuidar tu aspecto. Incluso las uñas tienen que tener buen aspecto,
            aunque se notan mucho menos que la cara. El cuidado de las uñas es
            una parte natural de tu rutina diaria de aseo y te mantendrá con un
            aspecto estupendo mientras haces streaming. La manicura y la
            pedicura son bastante sencillas y baratas.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image12}
              alt="Manicura 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image13}
              alt="Manicura 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Pose y postura
          </h2>
          <p className="text-gray-700 mb-4">
            Una pose bella (así como la capacidad de mantenerla y cambiarla) es
            fundamental y hace que los modelos resulten más atractivos para los
            usuarios. El lenguaje corporal puede expresar muchas emociones. El
            estatus y la confianza en una misma se transmiten en cómo te mueves
            y utilizas el espacio que te rodea. Debes tenerlo en cuenta a la
            hora de instalar los equipos y crear tu entorno de streaming.
          </p>
          <p className="text-gray-700 mb-4">
            Además, recuerda que tu capacidad de estar ante la cámara afecta a
            las emociones e impresiones que provocas en el público que ve tu
            espectáculo.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image14}
              alt="Pose 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image15}
              alt="Pose 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image16}
              alt="Pose 3"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>

        {/* Section: Cabello */}

        {/* Section: Peinados para Mujer */}
        <div className="space-y-4 border-t pt-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            Peinados para mujer
          </h3>
          <p className="text-gray-700 mb-4">
            Asegúrate de que tu peinado resalte tu estilo y otros rasgos de tu
            aspecto.
          </p>
          <p className="text-gray-700 mb-4">
            Péinate como más te guste. Sé seductora sin dejar de ser sincera.
            Cuanto más sencillo sea tu estilo, más probable será que los
            usuarios te perciban como una persona real. Mantente siempre fiel a
            ti misma y exhibe tu belleza al máximo.
          </p>
          <p className="text-gray-700 mb-4">
            No tengas miedo de utilizar accesorios para hacer tu look más
            interesante. ¡Sé creativa!
          </p>

          <div className="flex flex-wrap gap-4">
            <img
              src={image17}
              alt="Peinado 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image18}
              alt="Peinado 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Fondo</h2>
          <p className="text-gray-700 mb-4">
            Hay muchos consejos sobre cómo utilizar el diseño de interiores para
            conseguir un mejor streaming. Es muy sencillo; lo principal es crear
            un ambiente adecuado. Los usuarios se darán cuenta inmediatamente si
            no se adapta a tu estilo o a tu espectáculo. No te centres en
            utilizar muchos pequeños accesorios, desordenarán tu espacio.
          </p>
          <p className="text-gray-700 mb-4">
            Utiliza diferentes decoraciones para hacer más atractivo el diseño
            de tu espacio. A la hora de elegir un color de decoración, ten en
            cuenta tu tono de piel, pelo, ojos, ropa, etc. Cuanto más organizado
            esté tu espacio, más acogedora le parecerá tu sala de chat a los
            usuarios.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image19}
              alt="Fondo 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image20}
              alt="Fondo 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>

        {/* Section: Ángulo de la cámara */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ángulo de la cámara
          </h2>
          <p className="text-gray-700 mb-4">
            Vale la pena cambiar el ángulo de la cámara de vez en cuando. Por
            ejemplo, prueba a colocar la cámara más alta o más baja en lugar de
            grabar todo a la altura de los ojos y en una orientación horizontal.
            Siéntate con la cámara colocada sobre la cabeza, cambia el fondo,
            desplázate a otro lugar, etc.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image21}
              alt="Ángulo de la cámara"
              className="rounded-lg w-40 h-40 object-contain"
            />
          </div>
          <p className="text-gray-700 mt-4">
            Para tu comodidad, puedes utilizar la cámara con un mando a
            distancia para cambiar el ángulo en cualquier momento y ajustar el
            brillo de la imagen a distancia. El mando a distancia también te
            permite apagar rápidamente la cámara, aunque no la tengas a mano.
          </p>
        </div>

        {/* Section: Objetivo de la cámara */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Objetivo de la cámara
          </h2>
          <p className="text-gray-700 mb-4">
            El éxito de tu streaming no sólo depende de la calidad del equipo.
            También hay que garantizar unas condiciones de trabajo óptimas.
            Asegúrate de mantener el objetivo de la cámara limpio y seguro.
          </p>
          <div className="flex flex-wrap gap-4">
            <img
              src={image22}
              alt="Objetivo de la cámara"
              className="rounded-lg w-40 h-40 object-contain"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Iluminación</h2>
          <p className="text-gray-700 mb-4">
            Una buena imagen hará que tu emisión destaque entre otras modelos.
            Obtendrás resultados mucho mejores si la iluminación es la adecuada.
          </p>
          <p className="text-gray-700 mb-4">
            Antes de empezar a emitir tu streaming, coloca la cámara en
            distintos lugares y busca el sitio con la mejor iluminación. No
            grabes constantemente sólo de frente o de lado. Experimenta y busca
            nuevos ángulos.
          </p>
          <p className="text-gray-700 mb-4">
            Si te conectas a Internet a través de una cámara web, evita la
            iluminación que sea excesivamente brillante, que puede hacer que la
            imagen se vea borrosa y difuminada. Al mismo tiempo, utiliza fuentes
            de luz adicionales para que caiga menos sombra sobre ti durante la
            grabación.
          </p>

          <div className="flex flex-wrap gap-4">
            <img
              src={image23}
              alt="Iluminación 1"
              className="rounded-lg w-36 h-44 object-cover"
            />
            <img
              src={image24}
              alt="Iluminación 2"
              className="rounded-lg w-36 h-44 object-cover"
            />
          </div>
        </div>

        {/* Section: Ejemplos de iluminación óptima */}
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ejemplos de iluminación óptima
          </h2>
          <p className="text-gray-700 mb-4">
            Una iluminación adecuada es un factor fundamental para garantizar el
            éxito del streaming. La mejor manera de evitar problemas es
            prepararse con antelación. Prueba a experimentar con la iluminación
            interior en diferentes condiciones y posiciones. Cuanto más te
            esfuerces, mejores serán tus flujos.
          </p>
          <p className="text-gray-700 mb-4">Veamos los siguientes ejemplos:</p>
          <p className="text-gray-700 mb-4">
            Observe las principales diferencias de color y brillo.
          </p>
          <p className="text-gray-700 mb-4">
            Para garantizar una buena iluminación, debes utilizar las lámparas
            más adecuadas.
          </p>
          <p className="text-gray-700 mb-4">
            Sólo creando la iluminación óptima para la grabación conseguirás una
            retransmisión de alta calidad y garantizarás la mejor experiencia a
            los usuarios de tu chat. Por el bien de los usuarios, debes
            evolucionar constantemente como modelo y siempre innovar con nuevas
            cosas.
          </p>

          <div className="flex flex-wrap gap-4">
            <img
              src={image25}
              alt="Ejemplo 1"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image26}
              alt="Ejemplo 2"
              className="rounded-lg w-32 h-40 object-cover"
            />
            <img
              src={image27}
              alt="Ejemplo 3"
              className="rounded-lg w-32 h-40 object-cover"
            />
          </div>
        </div>
        <div className="space-y-4 border-t pt-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            ¿Qué es un cuestionario sobre sexo?
          </h2>
          <p className="text-gray-700">
            Los cuestionarios sobre sexo permiten a los usuarios hacer preguntas
            de pago. Con ellos, podrás conseguir ingresos adicionales. Para
            activar el cuestionario sobre sexo, debes rellenar un formulario con
            15 preguntas obligatorias. El cuestionario se publicará en tu perfil
            para que los usuarios puedan hacerte preguntas.
          </p>
        </div>

        {/* Section 2 */}
        <div className="border-b pb-4">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            ¿Qué ventajas tiene hacer un cuestionario sobre sexo?
          </h2>
          <p className="text-gray-700 mb-2">
            Al activar el cuestionario sexual, podrás ganar dinero extra
            simplemente respondiendo a las preguntas de los usuarios. Además, un
            cuestionario completado hará tu perfil más atractivo, y la
            oportunidad de hacer preguntas explícitas atraerá más atención de
            los usuarios.
          </p>
          <p className="text-gray-700">
            Intenta responder de manera detallada y sincera, esto aumentará el
            interés de los usuarios y les animará a hacerte más preguntas de
            pago.
          </p>
        </div>

        {/* Section 3 */}
        <div className="border-b pb-4">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            ¿Cómo activo el cuestionario sobre sexo?
          </h2>
          <p className="text-gray-700 mb-2">
            Puedes activar el cuestionario sobre sexo desde tu cuenta personal.
          </p>

          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Sigue los siguientes pasos:
          </h3>
          <ol className="list-decimal list-inside text-gray-700 space-y-2">
            <li>
              En la sección <strong>«Perfil, fotos, vídeos»</strong>, entra en
            </li>
            <li>
              Rellena el formulario respondiendo a 15 preguntas obligatorias.
            </li>
            <li>
              Establece el precio que pagarán los usuarios por hacer la
              pregunta:
              <ul className="list-disc list-inside ml-4 space-y-1">
                <li>Precio por defecto – 20 Tokens</li>
                <li>Precio mínimo – 1 Token</li>
                <li>Precio máximo – 2000 Tokens</li>
              </ul>
            </li>
            <li>
              Activa la opción{" "}
              <strong>«Deja que los miembros te hagan preguntas»</strong> y haz
              clic en
              <strong> «Publicar cuestionario sobre sexo».</strong>
            </li>
          </ol>
        </div>

        {/* Section 4 */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            ¿A quién y dónde estarán visibles las preguntas y respuestas de
            pago?
          </h2>
          <p className="text-gray-700 mb-2">
            Si has hecho un cuestionario y has permitido que los usuarios te
            hagan preguntas de pago, aparecerá el bloque{" "}
            <strong>«Cuestionario sobre sexo»</strong> en tu perfil, que será
            visible para todos los usuarios, incluidos los no autorizados.
          </p>
          <p className="text-gray-700">
            Si lo deseas, los usuarios pueden hacerte preguntas de forma
            anónima. En este caso, sus nombres quedarán ocultos tanto para ti
            como para los demás usuarios.
          </p>
        </div>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Pagos bajo petición
        </h2>

        {/* Description */}
        <p className="text-gray-700 mb-4">
          Para las modelos y los estudios registrados como particulares, también
          están disponibles los pagos bajo petición, que permiten retirar el
          dinero ganado en cualquier momento. Para hacerlo, solo tienes que
          indicar la cantidad que deseas retirar de tu saldo, hacer clic en
          <a href="#" className="text-blue-600 hover:underline">
            {" "}
            «Solicitar un pago»
          </a>
          , todo desde la página y en 30 minutos lo recibirás en tu monedero
          electrónico.
        </p>

        <p className="text-gray-700 mb-4">
          Todas las modelos y estudios pueden solicitar pagos bajo petición,
          siempre que hayan retirado previamente la cantidad mínima de su saldo
          (<strong>$50</strong> para las modelos y <strong>$100</strong> para
          los estudios).
        </p>

        <p className="text-gray-700 mb-4">
          Los pagos bajo petición están disponibles para los monederos{" "}
          <strong>Paxum</strong> y <strong>WebMoney</strong>. También puedes
          solicitar pagos en las criptomonedas <strong>Bitcoin</strong>,{" "}
          <strong>Ethereum</strong>,<strong>Tether USDT (ERC-20)</strong>,{" "}
          <strong>Tether USDT (TRC-20)</strong>,{" "}
          <strong>Tether USDT (SOL)</strong>,<strong>USD Coin (ERC-20)</strong>{" "}
          y <strong>USD Coin (SOL)</strong>.
        </p>

        {/* Minimum Amounts */}
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          Importes mínimos de pagos bajo petición:
        </h3>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>WebMoney, Paxum:</strong> $20
          </li>
          <li>
            <strong>Criptomonedas:</strong> $50
          </li>
        </ul>

        <p className="text-gray-700 mt-2">
          El importe máximo que se puede retirar bajo petición es de{" "}
          <strong>$500 al día</strong>.
        </p>

        {/* Commissions */}
        <h3 className="text-xl font-semibold text-gray-800 mt-4 mb-2">
          Las comisiones establecidas que se cobrarán para la retirada de fondos
          bajo petición son:
        </h3>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>WebMoney:</strong> 3% del importe retirado, como mínimo $1
          </li>
          <li>
            <strong>Paxum:</strong> $1
          </li>
        </ul>

        {/* Crypto Commissions */}
        <h3 className="text-xl font-semibold text-gray-800 mt-4 mb-2">
          Comisiones por retirada de fondos en criptomoneda:
        </h3>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Bitcoin:</strong> 1.5% + 0.0004 BTC
          </li>
          <li>
            <strong>Ethereum:</strong> 1.5% + 0.003 ETH
          </li>
          <li>
            <strong>Tether USDT (ERC-20):</strong> 1.5% + 0.003 ETH
          </li>
          <li>
            <strong>Tether USDT (TRC-20):</strong> 1.5% + 2 TRX
          </li>
          <li>
            <strong>Tether USDT (SOL):</strong> 1.5% + 0.0001 SOL
          </li>
          <li>
            <strong>USD Coin (ERC-20):</strong> 1.5% + 0.003 ETH
          </li>
          <li>
            <strong>USD Coin (SOL):</strong> 1.5% + 0.0001 SOL
          </li>
        </ul>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          ¿Dónde puedo obtener mi TIN (NIF)?
        </h2>
        <p className="text-gray-700 mb-4">
          Su número de identificación fiscal (NIF) suele figurar en documentos
          oficiales como:
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>Documento de identidad</li>
          <li>Pasaporte</li>
          <li>Permiso de conducir</li>
        </ul>

        <p className="text-gray-700 mt-4">
          También puede aparecer en documentos financieros, como:
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>Talones de pago</li>
          <li>Declaraciones fiscales</li>
          <li>Declaraciones de pensiones</li>
        </ul>

        <p className="text-gray-700 mt-4">
          Si tienes dificultades para localizar tu NIF, contacta con las
          autoridades fiscales de tu país para recibir asistencia.
        </p>

        <hr className="border-t border-gray-300 my-6" />

        {/* Section: Fiscal Authorities */}
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          ¿Informan de mis actividades en la web a las autoridades fiscales?
        </h2>
        <p className="text-gray-700">
          No, BongaCams sólo está obligada a facilitar a las autoridades
          fiscales información sobre los ingresos de los modelos que hayan
          indicado que su lugar de residencia es uno de los países de la UE. El
          informe no menciona la naturaleza de sus actividades en la web.
        </p>

        <hr className="border-t border-gray-300 my-6" />

        {/* Section: Registration */}
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Registro</h2>
        <p className="text-gray-700 mb-4">
          Para crear una cuenta, haz clic en el botón de registro de modelos.
          Selecciona tu tipo de cuenta (individual, pareja/grupo o estudio),
          rellena los campos y supera la verificación de que eres mayor de 18
          años. A continuación, haz clic en el botón{" "}
          <strong>«Crear cuenta»</strong>.
        </p>

        <p className="text-gray-700 mb-4">
          Posteriormente, selecciona el tipo de registro:
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Registro estándar:</strong> consta de 7 pasos obligatorios.
            Una vez completado, tendrás que esperar a la verificación de la
            cuenta.
          </li>
          <li>
            <strong>Registro rápido:</strong> implica la verificación automática
            de la identidad y el acceso rápido a los streaming en línea. Si la
            verificación es correcta, el estado de la cuenta cambiará a
            <strong> «Verificada»</strong> una vez completado el registro.
          </li>
        </ul>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">Documentos</h3>
        <p className="text-gray-700 mb-4">
          Carga tres fotos: del DNI, de la cara y otra en la que aparezcas
          sosteniendo el documento al lado de la cara.
        </p>
        <ol className="list-decimal list-inside text-gray-700 space-y-1 pl-4">
          <li>
            <strong>Foto del documento:</strong> Carga una foto de tu documento
            de identidad (carné de conducir, documento de identificación
            personal o pasaporte).
          </li>
          <li>
            <strong>Fotografía de la cara:</strong> Toma una instantánea de tu
            cara o sube una foto ya hecha.
          </li>
          <li>
            <strong>Fotografía de la cara y del documento:</strong>
            Carga una foto tuya en la que aparezcas sosteniendo tu documento de
            identificación a la altura de tu cara.
          </li>
        </ol>

        {/* Profile Information */}
        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-2">
          Información del perfil
        </h3>
        <p className="text-gray-700 mb-4">
          Los usuarios no pueden visualizar tus datos de contacto y de pago,
          sólo estarán disponibles para los administradores. No se mostrará tu
          fecha de nacimiento, pero debes introducir la información correcta.
        </p>
        <p className="text-gray-700">
          Algunos detalles del perfil serán visibles para otros usuarios del
          sitio. Debes tomarte este asunto en serio y rellenar el perfil con el
          mayor detalle posible.
        </p>

        {/* Profile Picture */}
        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-2">
          Fotografía de perfil
        </h3>
        <p className="text-gray-700 mb-4">
          Carga la foto principal del perfil. Elige la fotografía en la que te
          veas mejor. Recuerda que el perfil es como tarjeta de visita del
          sitio: es lo primero que ven los usuarios cuando visitan tu página.
        </p>

        {/* Payment Information */}
        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-2">
          Datos de pago
        </h3>
        <p className="text-gray-700 mb-4">
          Elige un método de pago e introduce los datos de pago correctos.
        </p>

        {/* User Agreement */}
        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-2">
          Acuerdo de BongaCams
        </h3>
        <p className="text-gray-700">
          Lee el acuerdo de BongaCams minuciosamente y acepta las condiciones.
          Luego haz clic en el botón <strong>«Enviar e ir a la cuenta»</strong>.
        </p>
        <div>
          <h2 className="text-xl font-bold text-gray-900 mt-10 mb-2">
            ¿Cuánto tiempo se tarda en eliminar un vídeo de una fuente ajena?
          </h2>
          <p className="text-gray-700">
            Por término medio, el trabajo de los abogados dura de 2 días a 4
            semanas (dependiendo de las particularidades de la fuente externa
            donde esté alojado el vídeo). Sin embargo, no se limita a estos
            plazos.
          </p>
        </div>

        {/* 2. Video Not Removed */}
        <div>
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            ¿Por qué no se ha retirado todavía un vídeo de una fuente ajena?
          </h2>
          <p className="text-gray-700">
            La razón más común es que la fuente externa ignora las solicitudes
            de eliminación de vídeos. En tales situaciones, el abogado utilizará
            otros métodos para eliminar el contenido, lo que puede llevar más
            tiempo. Si el enlace que proporcionaste no fue rechazado, la
            eliminación de tu vídeo aún está en proceso.
          </p>
        </div>

        {/* 3. Rejected Link */}
        <div>
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            ¿Por qué se rechazó el enlace enviado a los abogados?
          </h2>
          <p className="text-gray-700 mb-2">
            Lo más probable es que el enlace no cumpla los siguientes
            requisitos:
          </p>
          <ol className="list-decimal list-inside text-gray-700 space-y-1 pl-4">
            <li>
              El enlace proporcionado debe llevar a un vídeo o foto concretos.
              Se rechazará si el enlace conduce a los resultados de un motor de
              búsqueda, a una redirección o a la página de inicio de una fuente
              de terceros.
            </li>
            <li>
              La foto o el vídeo deben contener el logotipo de nuestro sitio y/o
              la marca de seguridad DMCA. En su ausencia, es casi imposible
              eliminar el contenido, por lo que el enlace puede ser rechazado.
              El enlace también puede rechazarse si el vídeo o la foto sólo
              contiene la marca de seguridad DMCA, pero el abogado ha
              determinado que el contenido pertenece a otro titular de derechos
              de autor.
            </li>
          </ol>
        </div>

        {/* 4. Viewing Expelled Members */}
        <div>
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            ¿Cómo puedo ver a los miembros expulsados?
          </h2>
          <p className="text-gray-700">
            La lista de miembros vetados se puede ver en la página de flujo
            haciendo clic en el icono <strong>⊗</strong> de la esquina superior
            derecha. Al hacer clic, se abrirá la sección{" "}
            <strong>«Prohibidos y silenciados»</strong>.
          </p>
        </div>

        {/* 5. Changing Private Chat Prices */}
        <div>
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            ¿Cómo puedo cambiar el precio de los chats privados?
          </h2>
          <p className="text-gray-700">
            Haz clic en <strong>«Iniciar difusión»</strong> en la esquina
            superior izquierda. En la ventana de configuración del flujo que se
            abre, ve al bloque
            <strong> «Configuración del chat»</strong> y cambia los precios de
            los chats de pago.
          </p>
        </div>

        {/* 6. Appointing a Moderator */}
        <div>
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            ¿Cómo nombro a un moderador?
          </h2>
          <p className="text-gray-700">
            Para ello, entra en tu cuenta modelo, ve a la sección{" "}
            <strong>«Suscripciones»</strong>
            haciendo clic en el icono <strong>☆</strong> de la parte superior de
            la página y seleccionando la pestaña{" "}
            <strong>«Mis moderadores»</strong>.
          </p>
        </div>
      </div>
      <div className="mt-10"></div>
      <Footer />
    </div>
  );
};

export default FAQ;
