// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css"; // Ensure that the path to Tailwind CSS is correct
import HeroPage from "./components/HeoPage";
import TermsAndConditions from "./components/TermsandCondition";
import FAQ from "./components/faqs";
import ContactForm from "./components/ContactForm";
import PaymentMethods from "./components/Payments";
import ComplianceNotice from "./components/Main";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HeroPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contáctanos" element={<ContactForm />} />
        <Route path="/payment" element={<PaymentMethods />} />
        <Route path="/2257" element={<ComplianceNotice />} />
      </Routes>
    </Router>
  );
}

export default App;
