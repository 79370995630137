import React, { useState, useEffect } from "react";
import Bg from "../assets/bg.jpg";
import SideImage from "../assets/ImageGirl.png";

const ContactUs = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [socialUsername, setSocialUsername] = useState("");
  const [screenName, setScreenName] = useState("");
  const [gender, setGender] = useState("");

  const [successPopup, setSuccessPopup] = useState(false); // Success Popup State

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    screenName: false,
  });

  const closePopup = () => setIsOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      firstName: firstName.trim() === "",
      lastName: lastName.trim() === "",
      email: email.trim() === "",
      screenName: screenName.trim() === "", // Check if screenName is empty
    };

    setErrors(newErrors);

    if (newErrors.firstName || newErrors.lastName || newErrors.email) {
      return;
    }

    setLoading(true);

    const formData = {
      firstName,
      lastName,
      email,
      socialUsername,
      screenName,
      gender,
    };

    try {
      const response = await fetch(
        "https://backend-upwork.vercel.app/api/send-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setSocialUsername("");
        setScreenName("");
        setGender("");
        setErrors({
          firstName: false,
          lastName: false,
          email: false,
          screenName: false,
        });

        setIsOpen(false); // Close the form popup
        setSuccessPopup(true); // Show success popup

        // Auto-hide success popup after 3 seconds
        setTimeout(() => {
          setSuccessPopup(false);
        }, 3000);
      } else {
        alert(`❌ Error: ${data.message}`);
      }
    } catch (error) {
      console.error("❌ Error sending email:", error);
      alert("❌ Failed to send email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Form Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md p-4">
          <div
            className="relative w-full max-w-4xl rounded-2xl shadow-2xl flex flex-col md:flex-row overflow-hidden border border-gray-800 bg-opacity-90 backdrop-blur-lg"
            style={{
              backgroundImage: `url(${Bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="relative w-full md:w-1/2 p-6 md:p-12 flex flex-col justify-center bg-opacity-90">
              <button
                onClick={closePopup}
                className="absolute top-4 right-4 text-white bg-black bg-opacity-50 hover:bg-opacity-80 p-2 rounded-full z-10"
              >
                ✖
              </button>

              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-6">
                Registro para Modelos Individuales
              </h2>

              {/* Gender Selection */}
              <div className="mb-4 flex flex-col md:flex-row items-center md:space-x-4">
                <p className="text-gray-700 text-sm font-semibold mb-2 md:mb-0">
                  Eres:
                </p>
                <div className="flex space-x-2 sm:space-x-4">
                  {["Femenino", "Masculino", "Transexual"].map((option) => (
                    <label
                      key={option}
                      className="flex items-center space-x-1 sm:space-x-2 cursor-pointer text-xs sm:text-sm"
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={option}
                        checked={gender === option}
                        onChange={(e) => setGender(e.target.value)}
                        className="w-3 h-3 sm:w-4 sm:h-4 text-gray-600"
                      />
                      <span className="text-gray-800">{option}</span>
                    </label>
                  ))}
                </div>
              </div>

              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                  <input
                    type="text"
                    placeholder="Nombre"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={`w-full p-3 border ${
                      errors.firstName ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 ${
                      errors.firstName
                        ? "focus:ring-red-500"
                        : "focus:ring-gray-500"
                    }`}
                  />
                  <input
                    type="text"
                    placeholder="Apellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={`w-full p-3 border ${
                      errors.lastName ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 ${
                      errors.lastName
                        ? "focus:ring-red-500"
                        : "focus:ring-gray-500"
                    }`}
                  />
                </div>

                <input
                  type="email"
                  placeholder="Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`w-full p-3 border ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 ${
                    errors.email ? "focus:ring-red-500" : "focus:ring-gray-500"
                  }`}
                />

                <input
                  type="text"
                  placeholder="Instagram o Twitter"
                  value={socialUsername}
                  onChange={(e) => setSocialUsername(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                />

                <input
                  type="text"
                  placeholder="Elige un Nombre Artístico"
                  value={screenName}
                  onChange={(e) => setScreenName(e.target.value)}
                  className={`w-full p-3 border ${
                    errors.screenName ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 ${
                    errors.screenName
                      ? "focus:ring-red-500"
                      : "focus:ring-gray-500"
                  }`}
                />

                <button
                  type="submit"
                  className="w-full bg-gray-500 text-white py-3 rounded-md font-bold hover:bg-gray-600 transition"
                >
                  {loading ? "Enviando..." : "Aplica Ahora"}
                </button>
              </form>
            </div>

            <div className="hidden md:block w-1/2 relative">
              <img
                src={SideImage}
                alt="Sign Up"
                className="absolute -bottom-6 -right-6 w-[80%] md:w-[130%] h-auto object-contain transform scale-110 drop-shadow-2xl"
              />
            </div>
          </div>
        </div>
      )}

      {/* Success Popup */}
      {successPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md p-4 transition-opacity duration-500 ease-in-out animate-fadeIn">
          <div className="w-full max-w-sm md:max-w-md p-4 bg-white md:p-6 rounded-2xl shadow-2xl bg-opacity-90 text-white border border-gray-800">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-left pl-4">
              🎉 Solicitud recibida!
            </h2>
            <p className="text-sm md:text-base text-gray-900 leading-relaxed text-left pl-4">
              Hemos recibido tu solicitud. Nuestro equipo de atención al cliente
              se pondrá en contacto contigo en un plazo de 24 a 48 horas.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUs;
