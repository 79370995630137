import React from "react";
import Slider from "react-slick";
import AwardImage from "../assets/icon.png";
import { ChevronLeft, ChevronRight } from "lucide-react";

const awards = [
  { title: "MEJOR PLATAFORMA FREEMIUM DE CÁMARAS EN VIVO", year: "2023" },
  { title: "PLATAFORMA FREEMIUM DEL AÑO", year: "2022" },
  { title: "MEJOR PLATAFORMA FREEMIUM", year: "2021" },
  { title: "MEJOR MARCA DE INTERNET", year: "2021" },
  { title: "MEJORES SERVICIOS EMPRESARIALES", year: "2019" },
];

// Custom Previous Arrow
const CustomPrevArrow = ({ onClick }) => (
  <button
    className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-400 bg-opacity-50 rounded-full p-1 hover:bg-gray-600 transition-colors z-10 md:p-2"
    onClick={onClick}
  >
    <ChevronLeft className="text-white w-5 h-5 md:w-6 md:h-6" />
  </button>
);

// Custom Next Arrow
const CustomNextArrow = ({ onClick }) => (
  <button
    className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-400 bg-opacity-50 rounded-full p-1 hover:bg-gray-600 transition-colors z-10 md:p-2"
    onClick={onClick}
  >
    <ChevronRight className="text-white w-5 h-5 md:w-6 md:h-6" />
  </button>
);

const AwardsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 on mobile
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile Screens
        settings: {
          slidesToShow: 1, // Show 1 slide at a time on small screens
          arrows: false, // Hide arrows on mobile for better usability
          dots: true, // Keep dots for navigation
        },
      },
    ],
  };

  return (
    <div className="bg-gray-200 py-8 px-4 md:py-16 md:px-8">
      <div className="container mx-auto text-center">
        {/* Section Heading */}
        <h2 className="text-2xl md:text-4xl font-bold text-gray-800 ">
          ÚNETE AL MEJOR SITIO DE CÁMARAS
        </h2>
        <h3 className="text-lg md:text-2xl text-red-500 font-semibold">
          AW AWARDS
        </h3>

        {/* Awards Carousel */}
        <Slider {...settings} className="relative">
          {awards.map((award, index) => (
            <div key={index} className="p-2 md:p-4">
              <div className="flex flex-col items-center">
                {/* Award Badge */}
                <img
                  src={AwardImage}
                  alt={`Award ${index + 1}`}
                  className="w-20 h-20 md:w-32 md:h-32 mb-2 md:mb-4"
                />
                {/* Award Title */}
                <p className="text-sm md:text-lg font-bold text-gray-800 text-center">
                  {award.title}
                </p>
                {/* Award Year */}
                <p className="text-blue-500 text-xs md:text-sm font-medium mt-1 md:mt-2">
                  {award.year}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AwardsCarousel;
